import React, { useEffect, useState } from 'react';
import { Checkbox, Col, DatePicker, Divider, Input, message, Radio, Row, Select, Spin, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AuthenticatedNavbar } from '@components/layoutComponents';
import { useGrades, useStudents, useTopics } from '@hooks';
import { useAppState } from '@context';
import { ArrowUpRight, HelpCircle as HelpCircleIcon } from 'react-feather';

import { Badge, Button, Card, Colors, Form, Link, Stack, Text, Title } from '@design-system';

import { getApiWithAuth, patchApiWithAuth, postApiWithAuth, getAssignmentStatus, getTopic } from '@utils';
import dayjs from 'dayjs';

import { MissionModal } from '@pages/Assignments/MissionModal';
import { AssignmentQuestionsPdfModal } from '@pages/Assignments/PdfModals';
import moment from 'moment';

const { Option } = Select;

const getStudentName = student => {
  let studentName = `${student.first_name} ${student.last_name}.`.trim();
  if (studentName === '') studentName = `${student.username}`.trim();

  if (studentName === '') studentName = `${student.username}`.trim();

  return studentName;
};

const getUniqueCurriculums = data => {
  const curriculumsSet = new Set();
  data.forEach(({ curriculums }) => {
    curriculums.forEach(({ name }) => curriculumsSet.add(name));
  });
  return [...curriculumsSet];
};

const endDateValidator = (startDate, endDate) => {
  if (!endDate) return false;
  if (startDate && startDate.isAfter(endDate)) {
    return 'new_assignment_end_date_error';
  }
  if (moment().isAfter(endDate)) {
    return 'new_assignment_end_date_early_error';
  }
  return false;
};

export const NewAssignment = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'assignments' });

  const { state } = useAppState();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const [assignmentStatus, setAssignmentStatus] = useState({ status: t('new'), textColor: '', backgroundColor: '' });

  const currentClass = state.classes.find(item => item.id === state.classId);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const gradeId = searchParams.get('grade_id') ? parseInt(searchParams.get('grade_id'), 10) : null;

  const { data: allStudents, loading: fetchingStudents } = useStudents(state.classId);
  const { data: grades, loading: fetchingGrades } = useGrades();
  const [formLoading, setFormLoading] = useState(true);
  const [missionPreview, setMissionPreview] = useState(null);
  const [assessmentPreview, setAssessmentPreview] = useState(null);
  const [grade, setGrade] = useState(gradeId);
  const [loading, setLoading] = useState(false);

  const name = Form.useWatch('name', form);
  const startDate = Form.useWatch('startDate', form);
  const endDate = Form.useWatch('endDate', form);
  const curriculum = Form.useWatch('curriculum', form);
  const topic = Form.useWatch('topic', form);
  const assignTo = Form.useWatch('assignTo', form);
  const missions = Form.useWatch('missions', form) ?? [];
  const specificStudents = Form.useWatch('students', form) ?? [];

  const [curriculums, setCurriculums] = useState([]);

  const { data: allTopics, loading: topicLoading } = useTopics(grades?.find(item => item.id === grade)?.name);

  const [topics, setTopics] = useState([]);

  const allMissions = topic && topics.find(item => item.id === topic)?.missions;
  const assessments = topic && topics.find(item => item.id === topic)?.assessments;

  const topicDetail = topic && topics && topics.find(item => item.id === topic);
  const assignTos = [t('entire_class'), t('specific_students')];
  const fetchingData = topicLoading || fetchingStudents || fetchingGrades;

  const updateAssignment = async () => {
    setLoading(true);
    const { success, data: plannerResponse } = await patchApiWithAuth(
      `school/class/${state.classId}/assignments/${id}/edit`,
      {
        name: name,
        start_date: dayjs(startDate).format('YYYY-MM-DD'),
        end_date: dayjs(endDate).format('YYYY-MM-DD'),
        is_class_assignment: assignTo === t('entire_class'),
        assigned_students: specificStudents
      }
    );
    if (success) {
      setLoading(false);
      navigate('/assignments');
    } else {
      message.error(plannerResponse.message);
      setLoading(false);
    }
  };

  const createAssignment = async () => {
    setLoading(true);
    const { success, data: plannerResponse } = await postApiWithAuth(
      `school/class/${state.classId}/assignments/create`,
      {
        name: name,
        start_date: dayjs(startDate).format('YYYY-MM-DD'),
        end_date: dayjs(endDate).format('YYYY-MM-DD'),
        topic: topic,
        grade: grade,
        missions: missions,
        assessments: assessments?.map(assessment => assessment.id),
        is_class_assignment: assignTo === t('entire_class'),
        assigned_students: specificStudents
      }
    );
    if (success) {
      setLoading(false);
      navigate('/assignments');
    } else {
      message.error(plannerResponse.message);
      setLoading(false);
    }
  };

  const setAssignTo = students => {
    if (allStudents?.length > 0 && students?.length > 0) {
      if (students.length === allStudents.length) {
        form.setFieldValue('assignTo', assignTos[0]);
      } else {
        form.setFieldValue('students', students);
        form.setFieldValue('assignTo', assignTos[1]);
      }
    }
  };

  useEffect(() => {
    if (allTopics && allTopics?.length > 0) {
      setCurriculums(getUniqueCurriculums(allTopics));
    }
  }, [allTopics]);

  // set current grade as default grade
  useEffect(() => {
    if (grades.length > 0) {
      if (grade === null && id === 'new') {
        setGrade(grades.find(item => item.name === currentClass.grade).id);
      }
    }
  }, [grades]);

  const selectGrade = gradeName => {
    setGrade(gradeName);
  };

  useEffect(() => {
    form.setFieldValue('grade', grade);
  }, [grade]);

  // set first curriculum as default if none available
  useEffect(() => {
    if (curriculums && curriculums?.length > 0 && id === 'new') {
      form.setFieldValue('curriculum', curriculums[0]);
    }
  }, [curriculums]);

  useEffect(() => {
    if (grade !== null && id === 'new') {
      form.setFieldValue('topic', null);
    }
  }, [grade]);

  useEffect(() => {
    if (curriculum) {
      setTopics(allTopics?.filter(item => item.curriculums.some(item => item.name === curriculum)));
    }
  }, [curriculum, allTopics]);

  useEffect(() => {
    if (assessments?.length) {
      form.setFieldValue(
        'assessments',
        assessments.map(item => item.id)
      );
    }
  }, [assessments]);

  useEffect(() => {
    if (allMissions?.length && id === 'new' && topic) {
      form.setFieldValue(
        'missions',
        allMissions.map(mission => mission.id)
      );
    }
  }, [allMissions, topic]);

  // set assignTo to Entire_Class when all students are selected
  useEffect(() => {
    if (allStudents?.length > 0 && specificStudents?.length > 0) {
      if (specificStudents.length === allStudents.length) {
        form.setFieldValue('assignTo', assignTos[0]);
      } else {
        form.setFieldValue('assignTo', assignTos[1]);
      }
    }
  }, [specificStudents, allStudents]);

  useEffect(async () => {
    if (id === 'new') {
      setFormLoading(false);
      return;
    }
    setFormLoading(true);
    const { success, data } = await getApiWithAuth(`school/assignments/${id}`);
    if (success) {
      setAssignTo(data?.student_ids);
      form.setFieldValue('topic', data?.topic?.id);
      form.setFieldValue('name', data?.name);
      setGrade(data?.grade?.id);
      form.setFieldValue('startDate', moment(data?.start_date, 'YYYY - MM - DD'));
      form.setFieldValue('endDate', moment(data?.end_date, 'YYYY - MM - DD'));
      form.setFieldValue(
        'missions',
        data?.missions?.map(mission => mission.id)
      );
      form.setFieldValue('curriculum', data?.curriculum_type);
      setAssignmentStatus(getAssignmentStatus(data['start_date'], data['end_date'], t, data?.status));
      setFormLoading(false);
    } else {
      setFormLoading(false);
    }
  }, [id]);

  const assignmentCompleted= [t('completed'), t('deleted')].includes(assignmentStatus?.status) || false;

  return (
    <>
      <div className="container-fluid p-2 p-md-3 p-lg-4 overViewMianDivHeight">
        <AuthenticatedNavbar
          smallHeading2={`${t('assign_learning_topics')}`}
          smallHeading3={!fetchingData && !formLoading ? `${id === 'new' ? t('create_assignment') : assignmentCompleted ? t('past_assignment') : t('edit_assignment')}`: ''}
        />
        <Stack direction="column" alignItems="flex-start" spacing={30} style={{ marginTop: 22 }}>
          <Title level={2}>{id === 'new' ? t('create_assignment') : t('edit_assignment')}</Title>
          {fetchingData || formLoading ? (
            <Card width="100%">
              <Stack direction="column" justifyContent="center" style={{ minHeight: '200px' }}>
                <Spin size="large" />
              </Stack>
            </Card>
          ) : (
            <Form data-testid='assignment-form' requiredMark={false} form={form} onFinish={id === 'new' ? createAssignment : updateAssignment}>
              <Row style={{ width: '100%' }}>
                <Col
                  style={{ paddingBottom: 30 }}
                  xs={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 14 }}
                  xl={{ span: 15 }}>
                  <Stack direction="column" alignItems="flex-start" spacing={30}>
                    <Form.Item
                      name="name"
                      label={t('assignment_name')}
                      data-testid='assignment-name'
                      rules={[{ required: true, message: t('new_assignment_name_missing') }]}>
                      <Input
                        disabled={assignmentCompleted}
                        placeholder={'Test Assignment'}
                      />
                    </Form.Item>
                    <Stack alignItems="flex-start">
                      <Form.Item
                        name="startDate"
                        label={t('start_date')}
                        data-testid='assignment-start-date'
                        rules={[{ required: true, type: 'object', message: t('new_assignment_start_date_missing') }]}>
                        <DatePicker
                          placeholder={t('new_assignment_date_placeholder')}
                          suffixIcon={false}
                          disabled={[t('active'), t('due_today'), t('completed'), t('deleted')].includes(assignmentStatus.status)}
                          format="YYYY-MM-DD"
                        />
                      </Form.Item>
                      <Form.Item
                        name="endDate"
                        data-testid='assignment-end-date'
                        label={t('end_date')}
                        rules={[
                          { required: true, message: t('new_assignment_end_date_missing') },
                          () => ({
                            validator(_, value) {
                              const error = endDateValidator(startDate, value);
                              if (error) {
                                return Promise.reject(new Error(t(error)));
                              }
                              return Promise.resolve();
                            }
                          })
                        ]}>
                        <DatePicker
                          placeholder={t('new_assignment_date_placeholder')}
                          suffixIcon={false}
                          disabled={assignmentCompleted}
                          format="YYYY-MM-DD"
                        />
                      </Form.Item>
                    </Stack>
                    <Form.Item data-testid='assignment-grade' name="grade" label={t('select_grade')}>
                      <Select
                        disabled={[t('starting_soon'), t('active'), t('due_today'), t('completed'), t('deleted')].includes(
                          assignmentStatus?.status
                        )}
                        onChange={selectGrade}
                        value={grade}>
                        {grades?.map(item => (
                          <Option key={item.name} value={item.id}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    {curriculums?.length > 0 && (
                      <Form.Item
                        data-testid='assignment-curriculum'
                        name="curriculum"
                        label={t('select_curriculums')}
                        rules={[{ required: true, message: t('new_assignment_curriculum_missing') }]}>
                        <Radio.Group
                          disabled={[t('starting_soon'), t('active'), t('due_today'), t('completed'), t('deleted')].includes(
                            assignmentStatus.status
                          )}
                          options={curriculums}
                        />
                      </Form.Item>
                    )}
                    {topics?.length > 0 && (
                      <Form.Item
                        name="topic"
                        label={t('select_topic')}
                        data-testid='assignment-topic'
                        rules={[{ required: true, message: t('new_assignment_topic_missing') }]}>
                        <Select
                          disabled={[t('starting_soon'), t('active'), t('due_today'), t('completed'), t('deleted')].includes(
                            assignmentStatus?.status
                          )}
                          optionLabelProp="label">
                          {topics.map(item => (
                            <Option
                              style={{ borderBottom: `2px solid ${Colors.LAVENDER_50}` }}
                              key={item.name}
                              label={item.name}
                              value={item.id}
                              disabled={item.is_locked}>
                              <Stack justifyContent="space-between" style={{ padding: '10px 20px' }}>
                                {item.name}
                                <Badge type={getTopic(item?.sub_heading.name)}>{item?.sub_heading.name}</Badge>
                              </Stack>
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}
                    {(allMissions?.length > 0 || assessments?.length > 0) && (
                      <Stack direction="column" spacing={0}>
                        {allMissions?.length > 0 && (
                          <Form.Item
                            name="missions"
                            data-testid='assignment-missions'
                            validateTrigger="onSubmit"
                            label={t('select_missions')}
                            tooltip={{
                              color: 'purple',
                              title: t('new_assignment_at_least_one_mission'),
                              icon: <HelpCircleIcon size={20} color={Colors.WHITE_0} fill={Colors.VIOLET} />
                            }}
                            rules={[{ required: true, message: t('new_assignment_at_least_one_mission') }]}>
                            <Checkbox.Group style={{ width: '100%' }}>
                              {allMissions.map(mission => (
                                <Checkbox
                                  style={{ width: '100%' }}
                                  disabled={[t('starting_soon'), t('active'), t('due_today'), t('completed'), t('deleted')].includes(
                                    assignmentStatus?.status
                                  )}
                                  value={mission.id}
                                  key={mission.id}>
                                  <Stack justifyContent="space-between">
                                    {mission.name}
                                    <Link
                                      icon={<ArrowUpRight />}
                                      onClick={e => {
                                        e.preventDefault();
                                        setMissionPreview(mission.id);
                                      }}
                                      size="small">
                                      {t('preview_mission')}
                                    </Link>
                                  </Stack>
                                </Checkbox>
                              ))}
                            </Checkbox.Group>
                          </Form.Item>
                        )}
                        {assessments?.length > 0 && (
                          <Form.Item data-testid='assignment-assessments' name="assessments">
                            <Checkbox.Group style={{ width: '100%' }}>
                              {assessments.map(assessment => (
                                <Checkbox style={{ width: '100%' }} disabled value={assessment.id} key={assessment.id}>
                                  <Stack justifyContent="space-between">
                                    <Stack>
                                      {assessment.name}
                                      <Tooltip color="purple" title={t('new_assignment_assessment_tooltip')}>
                                        <HelpCircleIcon
                                          style={{ marginLeft: 5 }}
                                          size={20}
                                          color={Colors.WHITE_0}
                                          fill={Colors.VIOLET}
                                        />
                                      </Tooltip>
                                    </Stack>
                                    <Link
                                      onClick={() => {
                                        setAssessmentPreview(assessment.id);
                                      }}
                                      size="small">
                                      {t('preview_assessment')}
                                    </Link>
                                  </Stack>
                                </Checkbox>
                              ))}
                            </Checkbox.Group>
                          </Form.Item>
                        )}
                      </Stack>
                    )}
                    <Form.Item
                      name="assignTo"
                      data-testid='assignment-assignTo'
                      label={t('assign_to')}
                      rules={[{ required: true, message: t('new_assignment_select_assign_to') }]}>
                      <Radio.Group disabled={assignmentCompleted} options={assignTos} />
                    </Form.Item>
                    {assignTo && assignTo === t('specific_students') && (
                      <Form.Item
                        name="students"
                        data-testid='assignment-students'
                        label={t('select_students')}
                        rules={[{ required: true, message: t('new_assignment_at_least_one_student') }]}>
                        <Select
                          className="fixCardInputField"
                          mode="multiple"
                          style={{ minHeight: '50px !important' }}
                          disabled={assignmentCompleted}
                          optionLabelProp="label">
                          {allStudents.map(student => {
                            const studentName = getStudentName(student);
                            return (
                              <Option
                                style={{ borderBottom: `2px solid ${Colors.LAVENDER_50}` }}
                                key={student.id}
                                value={student.id}
                                label={studentName}
                                name="assigned_students">
                                <Stack justifyContent="space-between" style={{ padding: '10px 20px' }}>
                                  {studentName}
                                  <Badge type="invalid">{student.grade}</Badge>
                                </Stack>
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    )}
                  </Stack>
                </Col>
                <Col
                  xs={{ span: 16, offset: 0 }}
                  md={{ span: 16, offset: 0 }}
                  lg={{ span: 9, offset: 1 }}
                  xl={{ span: 8, offset: 1 }}>
                  <Card data-testid='assignment-details'>
                    <Stack direction="column" alignItems="flex-start" spacing={20}>
                      {id === 'new' && <Title level={3}>{t('Summary')}</Title>}
                      {id !== 'new' && <Badge type={assignmentStatus.type}>{assignmentStatus.status}</Badge>}
                      <Stack direction="column" alignItems="flex-start">
                        <Text size="large" strong>
                          {name}
                        </Text>
                        <Stack justifyContent="space-between">
                          <Text size="small"> Start: {startDate && dayjs(startDate).format('YYYY-MM-DD')}</Text>
                          <Text size="small"> End: {endDate && dayjs(endDate).format('YYYY-MM-DD')}</Text>
                        </Stack>
                        <Text size="small">{grades?.find(item => item.id === grade)?.name}</Text>
                        <Text size="small">{curriculum}</Text>
                        {topic && <Text size="small">{topics?.find(item => item.id === topic)?.name}</Text>}
                        <Text size="small">
                          {missions?.reduce(
                            (accumulator, mission) =>
                              accumulator
                                ? `${accumulator}, ${allMissions?.find(item => item.id === mission)?.name}`
                                : allMissions?.find(item => item.id === mission)?.name,
                            ''
                          )}
                          {missions?.length ? ', ' : ''}
                          {assessments?.reduce(
                            (acc, assessment) => (acc ? `${acc}, ${assessment.name}` : assessment.name),
                            ''
                          )}
                        </Text>
                        <Divider />
                        {assignTo && (
                          <Stack alignItems="flex-start" direction="column" spacing={20}>
                            <Text size="small">{t('assign_to')}</Text>
                            <Stack style={{ flexWrap: 'wrap' }}>
                              {assignTo === t('entire_class') && (
                                <Text bold size="x-small">
                                  {t('entire_class')}
                                </Text>
                              )}
                              {assignTo === t('specific_students') &&
                                allStudents
                                  .filter(student => specificStudents.find(item => item === student.id))
                                  .map((student, i) => (
                                    <Text style={{ whiteSpace: 'nowrap' }} size="x-small" bold key={i}>
                                      {getStudentName(student)},
                                    </Text>
                                  ))}
                            </Stack>
                          </Stack>
                        )}
                      </Stack>
                      <Stack direction="column">
                        <Button
                          htmlType="submit"
                          data-testid='create-assignment-button'
                          block
                          disabled={assignmentCompleted}
                          loading={loading}>
                          {id === 'new' ? t('create_assignment') : t('save_changes')}
                        </Button>
                      </Stack>
                    </Stack>
                  </Card>
                </Col>
              </Row>
            </Form>
          )}
        </Stack>
      </div>
      {assessmentPreview && topicDetail && (
        <AssignmentQuestionsPdfModal
          isOpen
          onClose={() => {
            setAssessmentPreview(null);
          }}
          topic={topicDetail}
          grade={grades.find(item => item.id === grade)}
          assessmentId={assessmentPreview}
        />
      )}
      {topic && (
        <MissionModal
          show={!!missionPreview}
          missionId={missionPreview}
          handleOnCancel={() => {
            setMissionPreview(null);
          }}
        />
      )}
    </>
  );
};
export default NewAssignment;
