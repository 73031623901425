import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, message, Select, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Trash2 } from 'react-feather';
import { Button, Colors, Form, Modal, Stack, Text, Title } from '@design-system';

import { API_URL, patchApiWithAuth } from '@utils';
import { useGrades } from '@hooks';
import { useAppState } from '@context';

const { Option } = Select;

const EditStudentDetailsModal = ({ isOpen, onClose, handleOnFinish, selectedStudent }) => {
  const { t } = useTranslation('teacher', { keyPrefix: 'students' });

  const { state } = useAppState();
  const [form] = Form.useForm();
  const { data: grades } = useGrades();

  const [showSpinner, setShowSpinner] = useState(false);

  const handleDeleteClick = async id => {
    setShowSpinner(true);

    const { success, data } = await patchApiWithAuth(
      `${API_URL.ADD_STUDENTS_V2}${state.classId}/students/${id}/remove`
    );
    if (success) {
      message.success(t('student_removed_successfully'));
      handleOnFinish();
    } else {
      message.error(data.message);
    }

    setShowSpinner(false);
  };

  const handleSubmit = async values => {
    setShowSpinner(true);
    const { success, data } = await patchApiWithAuth(`${API_URL.SCHOOL_STUDENTS}/${selectedStudent.id}/edit`, values);
    if (success) {
      message.success(t('student_updated_successfully'));
      handleOnFinish();
    } else {
      message.error(data.message);
    }

    setShowSpinner(false);
  };

  useEffect(() => {
    if (selectedStudent) {
      form.setFieldsValue({
        name: `${selectedStudent.first_name} ${selectedStudent.last_name}`,
        grade: selectedStudent.grade,
        external_id: selectedStudent.external_id,
        student_email: selectedStudent.student_email ? selectedStudent.student_email : selectedStudent.email,
        parent_email: selectedStudent.parent_email
      });
    }
  }, [selectedStudent]);

  return (
    <Modal data-testid='edit-student-modal' width={585} centered={false} onCancel={onClose} open={isOpen}>
      <Form form={form} onFinish={handleSubmit}>
        <Stack direction="column" spacing={22}>
          <Stack direction="column" alignItems="flex-start" spacing={12}>
            <Title level={3}>{t('edit_student')}</Title>
            <Col span={22}>
            <Text size="small">{t('link_parents_details')}</Text>
            </Col>
            <Row style={{ width: '100%' }} justify="space-between">
              <Col span={15}>
                <Form.Item data-testid='edit-student-name' label={`${t('edit_student_name')}:`} name="name">
                  <Input type="text" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item data-testid='edit-student-grade' label={`${t('grade')}:`} required name="grade">
                  <Select name="grade">
                    {grades.map(item => (
                      <Option key={item.name} value={item.name}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item data-testid='edit-student-id' label={`${t('student_id')}:`} name="external_id">
              <Input type="text" />
            </Form.Item>
            <Form.Item
              data-testid='edit-student-email'
              label={`${t('student_email')}:`}
              rules={[
                {
                  type: 'email',
                  message: t('invalid_email')
                }
              ]}
              name="student_email">
              <Input type="email" />
            </Form.Item>
            <Form.Item
              data-testid='edit-parent-email'
              label={`${t('parent_email')}:`}
              rules={[
                {
                  type: 'email',
                  message: t('invalid_email')
                }
              ]}
              name="parent_email">
              <Input
                type="email"
                disabled={selectedStudent && selectedStudent.parent_email && selectedStudent.parent_email !== ''}
              />
            </Form.Item>
          </Stack>
          <Stack direction="column" spacing={12}>
            <Button data-testid='edit-student-button' block htmlType="submit" disabled={showSpinner} loading={showSpinner}>
              {t('save')}
            </Button>
            <Button
              type="text"
              data-testid='remove-student-button'
              style={{ color: Colors.VIOLET }}
              onClick={() => !showSpinner && handleDeleteClick(selectedStudent.id)}>
              <Trash2 style={{ marginRight: 8 }} size={18} />
              {t('remove_from_class')}
            </Button>
          </Stack>
        </Stack>
      </Form>
    </Modal>
  );
};

export default EditStudentDetailsModal;

EditStudentDetailsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleOnFinish: PropTypes.func.isRequired,
  selectedStudent: PropTypes.object.isRequired
};
