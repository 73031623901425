import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';

import HelpfulLinks from './HelpfulLinks';
import { AuthenticatedNavbar } from '@components/layoutComponents';
import { Button, Card, Stack } from '@design-system';
import CreateStudent from '@components/CreateStudent';
import ClassCodeCopyCard from '@components/ClassCodeCopyCard';
import StudentsList from '@components/StudentsList/StudentsList';
import GoogleStudentSync from './GoogleStudentSync';
import MicrosoftStudentSync from './MicrosoftStudentSync';
import { useFlag } from '@unleash/proxy-client-react';

const Students = () => {
  const { t } = useTranslation('teacher', { keyPrefix: 'students' });
  const [googleSync, setGoogleSync] = useState(false);
  const [microsoftSync, setMicrosoftSync] = useState(false);

  const showMicrosoftRostering = useFlag('teacher.microsoft_rostering');
  const showGoogleRostering = useFlag('teacher.google_rostering');

  return (
    <div data-testid="students-page" className="container-fluid p-2 p-md-3 p-lg-4 overViewMianDivHeight">
      {googleSync && <GoogleStudentSync isOpen={googleSync} onClose={() => setGoogleSync(false)} />}
      {microsoftSync && <MicrosoftStudentSync isOpen={microsoftSync} onClose={() => setMicrosoftSync(false)} />}
      <AuthenticatedNavbar title={t('students')} />
      <Row gutter={[16, 16]} style={{ marginTop: 30 }}>
        <Col span={24} md={24} sm={24}>
          <CreateStudent />
        </Col>
        <Col span={24} md={24} sm={24}>
          <Card data-testid="create-student">
            <Stack spacing={30} direction="column">
              <Stack alignItems="flex-start" direction="row" style={{ flexWrap: 'wrap' }}>
                {showGoogleRostering && (
                  <Button
                    data-testid="sync-with-google"
                    size="small"
                    htmlType="submit"
                    onClick={() => setGoogleSync(true)}>
                    {t('sync_with_google')}
                  </Button>
                )}
                {showMicrosoftRostering && (
                  <Button
                    data-testid="sync-with-microsoft"
                    size="small"
                    htmlType="submit"
                    onClick={() => setMicrosoftSync(true)}>
                    {t('sync_with_microsoft')}
                  </Button>
                )}
              </Stack>
            </Stack>
          </Card>
        </Col>
        <Col span={16} md={16} sm={24}>
          <StudentsList />
        </Col>
        <Col span={8} md={8} sm={24}>
          <Stack alignItems="flex-start" direction="column" spacing={20}>
            <ClassCodeCopyCard />
            <HelpfulLinks />
          </Stack>
        </Col>
      </Row>
    </div>
  );
};

export default Students;
