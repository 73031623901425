import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { Modal, Stack, Title } from '@design-system';
import { sendGaEvent } from '@utils';

const StyledReactPlayer = styled(ReactPlayer)`
  width: 100%;
  height: calc((80vw) * 0.5625) !important;
  overflow: hidden;
  border-radius: 20px;
`;

const SupportVideoPlayerModal = ({ video, isOpen, onClose }) => {
  const videoRef = useRef(null);
  const [isPlaying, setPlaying] = useState(false);

  return (
    <Modal data-testid='support-video-player-modal' destroyOnClose width={'80vw'} open={isOpen} onCancel={onClose} closable>
      <Stack direction="column" alignItems="flex-start" spacing={30}>
        <Title data-testid={`${video?.name}`} level={2}>{video?.name}</Title>
        <StyledReactPlayer
          width="100%"
          controls
          ref={videoRef}
          url={video?.url}
          playing={isPlaying}
          data-testid='support-video-player'
          onEnded={() => {
            setPlaying(false);
            sendGaEvent('video_ended', 'Video Ended', video?.name);
          }}
          onPlay={() => {
            setPlaying(true);
            sendGaEvent('video_play', 'Video Play', video?.name);
          }}
          onPause={() => {
            setPlaying(false);
            sendGaEvent('video_pause', 'Video Pause', video?.name);
          }}
        />
      </Stack>
    </Modal>
  );
};

export default SupportVideoPlayerModal;

SupportVideoPlayerModal.defaultProps = {
  video: null
};
SupportVideoPlayerModal.propTypes = {
  video: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};
