import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppState } from '@context';
import { getFlowComplete } from '@utils';
import { LanguageSwitcher } from '@components/commonComponents';
import { Colors, Stack, Text } from '@design-system';

import logo from '../../../assets/images/sideBarLogo.svg';

import './navbarStyle.css';

const Navbar = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'common' });
  const { state } = useAppState();
  const currentPath = window.location.pathname;
  const navigate = useNavigate();
  const isParentFlow = currentPath.includes('parent-');
  const isTeacherFlow = currentPath.includes('teacher-');
  const searchParams = new URLSearchParams(window.location.search);
  const isRostered = searchParams.get('rostered') === 'true';

  return (
    <div className="row w-100 pt-4">
      <div className="col col-3 d-flex justify-content-center mt-2 cursorPointer pe-5">
        <div className="pe-5">
          {isRostered ? (
            <img src={logo} alt="officerLogo" width="120" height="48" />
          ) : (
            <Link to={state.authToken !== null && getFlowComplete() ? '/dashboard' : '/'} className="labelStyle">
              <img src={logo} alt="officerLogo" width="120" height="48" />
            </Link>
          )}
        </div>
      </div>
      <div className="col col-4 mt-2 mt-md-3" />
      <div className="col col-5 d-flex justify-content-center">
        <Stack justifyContent='center'>
          {!isParentFlow && <LanguageSwitcher />}
          {isTeacherFlow && (
            <Text size="small" style={{ color: Colors.WHITE_0 }}>
              {t('already_have_account')}&nbsp;
              <Text
                bold
                size="small"
                onClick={() => navigate('/teacher-signin')}
                style={{ color: Colors.WHITE_0, borderBottom: '2px solid #F8CA42', cursor: 'pointer' }}>
                {t('log_in')}
              </Text>
            </Text>
          )}
          {(!isRostered && !isTeacherFlow) && (
          <Text bold size="small" onClick={() => window.open('https://www.cyberlegends.com', '_self')} style={{ cursor: 'pointer', color: Colors.WHITE_0 }}>
            {t('back_to_cyberlegends')}
          </Text>
          )}
        </Stack>
      </div>
    </div>
  );
};

export default Navbar;
