import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Stack, Text, Title } from '@design-system';
import LockedIcon from '@assets/images/keyLock.svg';
import { Image } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const UnlockEdubytesModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation('teacher', { keyPrefix: 'select_licence' });
  const navigate = useNavigate();

  const handleBuy = () => {
    onClose();
    navigate('/settings');
  };

  return (
      <Modal data-testid='unlock-edubytes-modal' centered={false} onCancel={onClose} open={isOpen}>
        <Stack direction="column" spacing={50}>
          <Stack direction="column" spacing={22}>
            <Image src={LockedIcon} preview={false} />
            <Stack direction="column" alignItems="center" spacing={20}>
              <Title level={3}>{t('unlock_edubytes_title')}</Title>
              <Text className="text-center">{t('unlock_edubytes_description')}</Text>
            </Stack>
          </Stack>
          <Stack justifyContent='center'>
            <Button data-testid='unlock-edubytes-confirm-button' onClick={handleBuy}>{t('buy_licence_button')}</Button>
          </Stack>
        </Stack>
      </Modal>
  );
};

export default UnlockEdubytesModal;

UnlockEdubytesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
