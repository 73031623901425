import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Skeleton } from 'antd';
import useSWR from 'swr';

import { API_URL } from '@utils';
import { AuthenticatedNavbar } from '@components/layoutComponents';
import { Colors, Card, Link, Stack, Text, Title } from '@design-system';
import SupportHelpfulLinks from '@components/SupportHelpfulLinks';
import GlossaryTermCard from '@components/GlossaryTermCard';

const alphabets = Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));

const SupportGlossary = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'support' });
  const [activeAlphabet, setActiveAlphabet] = useState('A');
  const { data, isLoading } = useSWR(API_URL.GET_GLOSSARY_TERMS);
  const results = data?.data;

  const filteredResults = results?.filter(result => result.Term[0] === activeAlphabet);
  const sortedResults = filteredResults?.sort((a, b) => a.Term.localeCompare(b.Term));

  const handleOnClick = alphabet => {
    setActiveAlphabet(alphabet);
  };
  return (
    <div data-testid="teacher-support-glossary" className="container-fluid p-2 p-md-3 p-lg-4 overViewMianDivHeight">
      <AuthenticatedNavbar title={t('glossary')} />
      <Row gutter={[24, 24]} style={{ marginTop: 30 }}>
        <Col span={16} md={16} sm={24} xs={24}>
          <Text size="small">{t('cyber_legends_glossary')}</Text>
        </Col>
        <Col span={24}>
          <Stack justifyContent='space-between' style={{ flexWrap: 'wrap' }}>
            <Text>{t('sort_by')}</Text>
            {alphabets.map(alphabet => (
              <Link
                hideIcon
                style={alphabet === activeAlphabet ? { color: Colors.GREY_200, flex: '1 1 20px'}: {flex: '1 1 20px'}}
                key={`${alphabet}_${Math.random()}`}
                onClick={() => handleOnClick(alphabet)}>
                {alphabet}
              </Link>
            ))}
          </Stack>
        </Col>
        <Row style={{ width: '100%', marginTop: 20 }} gutter={[16, 16]}>
          <Col span={16} md={16} sm={24} xs={24}>
            <Stack direction='column' alignItems='flex-start' spacing={15}>
            {isLoading &&
              <Stack direction='column' spacing={20}>
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </Stack>}
            {!isLoading && sortedResults.length === 0 && (
              <Card width='100%'>
                <Title level={3}>
                  {t('no_results_found')} &ldquo;{activeAlphabet}&quot;
                </Title>
              </Card>
            )}
            {!isLoading && sortedResults.map(result => (
              <GlossaryTermCard cardData={result} key={`${result.Topic}_${Math.random()}`} />
            ))}
            </Stack>
          </Col>
          <Col span={8} md={8} sm={24} xs={24}>
            <SupportHelpfulLinks hideGlossary />
          </Col>
        </Row>
      </Row>
    </div>
  );
};

export default SupportGlossary;
