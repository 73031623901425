import { SET_CLASSES, SET_CURRENT_CLASS_ID } from '@context';
import { getLocalStorageItem, setLocalStorageItem } from '@utils';

export const classReducer = (state, action) => {
  switch (action.type) {
    case SET_CURRENT_CLASS_ID: {
      setLocalStorageItem('class_id', action.payload);
      return { ...state, classId: action.payload };
    }

    case SET_CLASSES: {
      let classId = getLocalStorageItem('class_id');
      const classes = action.payload ?? [];

      if (classes.length > 0) {
        classId = classId && classId !== '' && classes.some(item => item.id === classId) ? classId : classes[0].id;
      } else {
        classId = '';
      }

      setLocalStorageItem('class_id', classId);
      return { ...state, classes: classes, classId: classId };
    }

    default:
      return state;
  }
};
