import React from 'react';
import { message, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { Card, Link, Text } from '@design-system';
import { useCards } from '@hooks';
import { API_URL, getApiWithAuth } from '@utils';

import PropTypes from 'prop-types';

export const Membership = ({ licenceInfo } ) => {
  const { t } = useTranslation('translation', { keyPrefix: 'settings' });

  const { loading } = useCards();



  const openCustomerPortal = async () => {
    const { success, data } = await getApiWithAuth(API_URL.SCHOOL.GET_TEACHER_CUSTOMER_PORTAL);
    if (success) {
      if (data.url) {
        window.location.href = data.url;
      } else {
        message.error(t('customer_portal_error'));
      }
    } else {
      message.error(data.message);

    }
  };

  if (loading) {
    return (
      <Card bordered={false} className="billing-info__container cardsStyling">
        <Skeleton active />
      </Card>
    );
  }

  return (
    <Card title={t('membership')}>
      <div>
        {(() => {
          switch (licenceInfo?.data?.organization?.type) {
            case 'HOUSE':
              return (
                <div>
                  <Text size="small">{t('regular_member')}</Text>
                  <Link onClick={openCustomerPortal}>{t('manage_membership')}</Link>
                </div>
              );
            case 'SCHOOL': case 'DISTRICT':
              return <Text size="small">{t('school_or_board_paid')}</Text>;
            default:
              return (
                <div>
                  <Text size="small">{t('regular_member')}</Text>
                  <Link onClick={openCustomerPortal}>{t('manage_membership')}</Link>
                </div>
              );
          }
        })()}
      </div>
    </Card>
  );
};

Membership.defaultProps = {
  licenceInfo: {}
};

Membership.propTypes = {
  licenceInfo: PropTypes.object
};