import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Skeleton } from 'antd';
import { Colors } from '@design-system';

const StyledCard = styled.div`
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 73px;
  width: 100%;
  align-items: flex-start;
  cursor: pointer;
  gap: 10px;
  margin-top: 10px;
  padding: 14px 22px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08);
  background-color: ${Colors.WHITE_0};
`;

const LoadingCards = React.memo(({ count, image, ...rest }) => {
  const items = new Array(count).fill(0);
  return (
    <>
      {items.map((value, index) => (
        <StyledCard key={index}>
          {image && <Skeleton.Image active />}
          <Skeleton active {...rest} />
        </StyledCard>
      ))}
    </>
  );
});

export default LoadingCards;

LoadingCards.defaultProps = {
  count: 2,
  image: false
};

LoadingCards.propTypes = {
  count: PropTypes.number,
  image: PropTypes.bool
};
