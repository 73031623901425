import React, { useEffect, useState } from 'react';
import { Button, Modal, Stack, Table, Text } from '@design-system';

import { useGoogleLogin } from '@react-oauth/google';
import { getApiWithAuth, postApiWithAuth } from '@utils';
import { message, Select, Spin } from 'antd';
import PropTypes from 'prop-types';
import { useAppState } from '@context';
import { useTranslation } from 'react-i18next';

const SCOPES =
  'https://www.googleapis.com/auth/classroom.profile.emails https://www.googleapis.com/auth/classroom.courses.readonly https://www.googleapis.com/auth/classroom.rosters.readonly';

const { Option } = Select;

const columns = t => [
  {
    title: t('google_email'),
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: t('google_first_name'),
    dataIndex: 'first_name',
    key: 'first_name'
  },
  {
    title: t('google_last_name'),
    dataIndex: 'last_name',
    key: 'last_name'
  },
  {
    title: t('google_courses'),
    dataIndex: 'course_names',
    key: 'course_names',
    render: course_names => course_names.join(', ')
  },
  {
    title: t('google_enrolled'),
    dataIndex: 'already_enrolled',
    key: 'already_enrolled',
    render: already_enrolled => (already_enrolled ? '✔️' : '❌')
  }
];

const GoogleStudentSync = ({ isOpen, onClose }) => {
  const { state } = useAppState();
  const { t } = useTranslation('teacher', { keyPrefix: 'students' });

  const [googleAccount, setGoogleAccount] = useState(null);
  const [updatedCourses, setUpdatedCourses] = useState(null);
  const [selectedCourses, setSelectedCourses] = useState(null);
  const [courses, setCourses] = useState(null);
  const [allStudents, setAllStudents] = useState(null);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [googleAccountLoading, setGoogleAccountLoading] = useState(false);
  const [syncStudentLoading, setSyncStudentLoading] = useState(false);
  const [selectedCourseLoading, setSelectedCourseLoading] = useState(false);

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    scope: SCOPES,
    onSuccess: async codeResponse => {
      setGoogleAccountLoading(true);
      const data = await postApiWithAuth('school/google/oauth', {
        auth_code: codeResponse.code,
        scope: 'google_classroom'
      });
      if (data.success) {
        setGoogleAccountLoading(false);
        setGoogleAccount(true);
        fetchCourses();
      } else {
        setGoogleAccountLoading(false);
        message.error('Error');
      }
    },
    onError: errorResponse => message.error(errorResponse)
  });

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedStudents(selectedRows);
    },
    getCheckboxProps: record => ({
      disabled: record.already_enrolled,
      email: record.email
    })
  };

  const fetchGoogleLogin = async () => {
    const data = await getApiWithAuth('school/google/oauth?scope=google_classroom');
    if (data.success) {
      setGoogleAccount(true);
      fetchCourses();
    } else {
      setGoogleAccount(false);
    }
  };

  const fetchCourses = async () => {
    const { data, success } = await getApiWithAuth(`school/google/courses?class_id=${state.classId}`);
    if (success) {
      setCourses(data.courses);
      setSelectedCourses(data.selected_courses);
    } else {
      message.error(t('google_fetch_course_error'));
    }
  };

  const postSelectedCourse = async () => {
    setSelectedCourseLoading(true);
    const data = await postApiWithAuth('school/google/courses', {
      courses_ids: selectedCourses,
      class_id: state.classId,
      scope: 'google_classroom'
    });
    if (data.success) {
      setGoogleAccount(true);
      setSelectedCourseLoading(false);
      setUpdatedCourses(true);
      fetchStudents();
    } else {
      setSelectedCourseLoading(false);
      message.error(t('google_post_course_error'));
    }
  };

  const fetchStudents = async () => {
    const { data, success } = await getApiWithAuth(`school/google/students?class_id=${state.classId}`);
    if (success) {
      const updatedStudents = data.map(student => ({
        ...student,
        key: student.email,
        course_names: student.course_ids.map(courseId => {
          const course = courses.find(course => course.id === courseId);
          return course ? course.name : courseId; // Replace with course name if found
        })
      }));
      setSelectedStudents([]);
      setAllStudents(updatedStudents);
    } else {
      message.error(t('google_fetch_students_error'));
    }
  };

  const postSyncStudents = async () => {
    setSyncStudentLoading(true);
    const data = await postApiWithAuth('school/google/students', {
      class_id: state.classId,
      students: selectedStudents
    });
    if (data.success) {
      setSyncStudentLoading(false);
      message.success(t('google_post_students_success'));
      fetchStudents();
    } else {
      setSyncStudentLoading(false);
      message.error(t('google_post_students_error'));
    }
  };

  const handleCoursesChange = values => {
    setSelectedCourses(values);
  };

  useEffect(() => {
    fetchGoogleLogin();
  }, []);

  return (
    <Modal data-testid="student-login-details-modal" width={585} centered={false} onCancel={onClose} open={isOpen}>
      <Stack spacing={30} direction="column">
        <Stack alignItems="flex-start" style={{ flexWrap: 'wrap' }}>
          {googleAccount === false ? (
            <Stack spacing={30} direction="column">
              <Text bold size="large">
                {t('google_authenticate_with_google')}
              </Text>
              <Button block size="small" loading={googleAccountLoading} onClick={() => googleLogin()}>
                {t('google_sync_with_google')}
              </Button>
            </Stack>
          ) : courses && !updatedCourses ? (
            <Stack spacing={30} direction="column">
              <Text bold size="large">
                {t('google_select_courses_to_sync')}
              </Text>
              <Select
                mode="multiple"
                placeholder={t('google_select_courses')}
                value={selectedCourses}
                className="fixCardInputField"
                onChange={handleCoursesChange}
                name="student_name">
                {courses.map(item => (
                  <Option key={item.id} value={item.id} label={item.name}>
                    {item.name}
                  </Option>
                ))}
              </Select>

              <Button block size="small" loading={selectedCourseLoading} onClick={() => postSelectedCourse()}>
                {t('google_next')}
              </Button>
            </Stack>
          ) : allStudents ? (
            <Stack spacing={30} direction="column">
              <Text bold size="large">
                {t('google_select_students')}
              </Text>
              {!syncStudentLoading && (
                <Table
                  rowSelection={{
                    type: 'checkbox',
                    ...rowSelection
                  }}
                  dataSource={allStudents}
                  columns={columns(t)}
                />
              )}
              <Button
                block
                size="small"
                disabled={selectedStudents.length === 0}
                loading={syncStudentLoading}
                onClick={() => postSyncStudents()}>
                {t('google_sync_students')}
              </Button>
            </Stack>
          ) : (
            <Stack style={{ paddingTop: 40, minHeight: 160 }} justifyContent="center">
              <Spin size="large" />
            </Stack>
          )}
        </Stack>
      </Stack>
    </Modal>
  );
};

GoogleStudentSync.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default GoogleStudentSync;
