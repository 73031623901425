import React from 'react';
import PropTypes from 'prop-types';
import { X as CloseIcon } from 'react-feather';
import Tour from 'reactour';
import styled from 'styled-components';
import { Colors } from '@design-system';
import FeatureWalkthroughPopup from './FeatureWalkthroughPopup';
import { API_URL, postApiWithAuth } from '@utils';
import { message } from 'antd';
import { useSchoolTutorial } from '@hooks';

const StyledTour = styled(Tour)`
  &.reactour__helper {
    padding: 30px;
    border-radius: 15px;
    background: #fff;
    width: 350px;
  }
  & .ploygon {
    transform: rotate(-90deg);
    left: -19px;
  }
  & .ploygon-top {
    transform: rotate(180deg);
    bottom: -19px;
    left: 14px;
  }
`;

const getSteps = (onClose, setOpenSubMenuKey) => [
  {
    selector: '.first-step',
    content: ({ step, goTo }) => (
      <FeatureWalkthroughPopup
        step={step}
        goTo={goTo}
        close={onClose}
        setOpenSubMenuKey={setOpenSubMenuKey}
        section="manage_class"
      />
    )
  },
  {
    selector: '.second-step',
    content: ({ step, goTo }) => (
      <FeatureWalkthroughPopup
        step={step}
        goTo={goTo}
        close={onClose}
        setOpenSubMenuKey={setOpenSubMenuKey}
        section="overview"
      />
    )
  },
  {
    selector: '.third-step',
    content: ({ step, goTo }) => (
      <FeatureWalkthroughPopup
        step={step}
        goTo={goTo}
        close={onClose}
        setOpenSubMenuKey={setOpenSubMenuKey}
        section="in_class_resource"
      />
    )
  },
  {
    selector: '.fourth-step',
    content: ({ step, goTo }) => (
      <FeatureWalkthroughPopup
        step={step}
        goTo={goTo}
        close={onClose}
        setOpenSubMenuKey={setOpenSubMenuKey}
        section="edubytes"
      />
    )
  },
  {
    selector: '.fifth-step',
    content: ({ step, goTo }) => (
      <FeatureWalkthroughPopup
        step={step}
        goTo={goTo}
        close={onClose}
        setOpenSubMenuKey={setOpenSubMenuKey}
        section="students"
      />
    )
  },
  {
    selector: '.sixth-step',
    content: ({ step, goTo }) => (
      <FeatureWalkthroughPopup
        step={step}
        goTo={goTo}
        close={onClose}
        setOpenSubMenuKey={setOpenSubMenuKey}
        section="assignments"
      />
    )
  },
  {
    selector: '.seventh-step',
    content: ({ step, goTo }) => (
      <FeatureWalkthroughPopup
        step={step}
        goTo={goTo}
        close={onClose}
        setOpenSubMenuKey={setOpenSubMenuKey}
        section="planner"
      />
    )
  },
  {
    selector: '.eighth-step',
    content: ({ step, goTo }) => (
      <FeatureWalkthroughPopup
        step={step}
        goTo={goTo}
        close={onClose}
        setOpenSubMenuKey={setOpenSubMenuKey}
        section="support"
      />
    )
  },
  {
    selector: '.ninth-step',
    content: ({ step, goTo }) => (
      <FeatureWalkthroughPopup
        step={step}
        goTo={goTo}
        close={onClose}
        setOpenSubMenuKey={setOpenSubMenuKey}
        section="referrals"
      />
    )
  },
  {
    selector: '.tenth-step',
    content: ({ step, goTo }) => (
      <FeatureWalkthroughPopup
        step={step}
        goTo={goTo}
        close={onClose}
        setOpenSubMenuKey={setOpenSubMenuKey}
        section="settings"
      />
    ),
    position: 'top'
  },
  {
    selector: '.eleventh-step',
    content: ({ step, goTo }) => (
      <FeatureWalkthroughPopup
        step={step}
        goTo={goTo}
        close={onClose}
        setOpenSubMenuKey={setOpenSubMenuKey}
        section="logout"
      />
    ),
    position: 'top'
  }
];

const FeatureWalkthrough = ({ isOpen, onClose, setOpenSubMenuKey }) => {
  const { revalidate } = useSchoolTutorial('teacher-tour');

  const handleClose = async () => {
    const { data, success } = await postApiWithAuth(
      API_URL.POST_SCHOOL_TUTORIALS_PROGRESS.replace(':slug', 'teacher-tour'),
      {
        origin: 'Teacher dashboard product tour',
        progress: 100,
        status: 'COMPLETED'
      }
    );
    if (success) {
      revalidate();
      onClose();
    } else {
      message.error(data.message);
    }
  };

  return (
    <StyledTour
      position="right"
      isOpen={isOpen}
      steps={getSteps(handleClose, setOpenSubMenuKey)}
      showNumber={false}
      showNavigation={false}
      showButtons={false}
      maskSpace={4}
      data-testid="feature-walkthrough"
      showCloseButton={false}>
      <CloseIcon
        className="position-absolute cursorPointer"
        style={{ right: '20px', top: '20px' }}
        size={24}
        color={Colors.LAVENDER}
        onClick={handleClose}
      />
    </StyledTour>
  );
};

export default FeatureWalkthrough;

FeatureWalkthrough.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setOpenSubMenuKey: PropTypes.func.isRequired
};
