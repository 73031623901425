import React from 'react';
import { Image } from 'antd';
import { Colors, Card, Stack, Title, Text } from '@design-system';
import { X as CloseIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';
import highScoreGame from '@assets/images/icon_highScoreGame.svg';
import lightBulb from '@assets/images/icon_lightBulb.svg';
import researchPaper from '@assets/images/icon_researchPaper.svg';
import searchEngine from '@assets/images/icon_searchEngine.svg';
import PropTypes from 'prop-types';

const AssignmentWhatsNew = ({ onCancel }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'assignments' });
  return (
    <Card data-testid='whats-new-card'>
      <Stack direction="column" spacing={20}>
        <Stack direction="column" alignItems="flex-start">
          <Title level={3}>{t('whats_new')}</Title>
          <Text size="x-small">{t('whats_new_detail')}</Text>
        </Stack>
        <Stack spacing={50} style={{ flexWrap: 'wrap' }}>
          <Stack direction="column" alignItems="flex-start" spacing={20} style={{ flex: '1 1 150px' }}>
            <Image src={lightBulb} preview={false} alt={t('260_missions')} />
            <Stack direction="column" alignItems="flex-start" spacing={4}>
              <Text size="x-small" bold>
                {t('260_missions')}
              </Text>
              <Text size="x-small" style={{ color: Colors.GREY_50 }}>
                {t('260_missions_detail')}
              </Text>
            </Stack>
          </Stack>
          <Stack direction="column" alignItems="flex-start" spacing={20} style={{ flex: '1 1 150px' }}>
            <Image src={highScoreGame} preview={false} alt={t('auto_graded_assessments')} />
            <Stack direction="column" alignItems="flex-start" spacing={4}>
              <Text size="x-small" bold>
                {t('auto_graded_assessments')}
              </Text>
              <Text size="x-small" style={{ color: Colors.GREY_50 }}>
                {t('auto_graded_assessments_detail')}
              </Text>
            </Stack>
          </Stack>
          <Stack direction="column" alignItems="flex-start" spacing={20} style={{ flex: '1 1 150px' }}>
            <Image src={researchPaper} preview={false} alt={t('auto_generated_reports')} />
            <Stack direction="column" alignItems="flex-start" spacing={4}>
              <Text size="x-small" bold>
                {t('auto_generated_reports')}
              </Text>
              <Text size="x-small" style={{ color: Colors.GREY_50 }}>
                {t('auto_generated_reports_detail')}
              </Text>
            </Stack>
          </Stack>
          <Stack direction="column" alignItems="flex-start" spacing={20} style={{ flex: '1 1 150px' }}>
            <Image src={searchEngine} preview={false} alt={t('manage_assignments')} />
            <Stack direction="column" alignItems="flex-start" spacing={4}>
              <Text size="x-small" bold>
                {t('manage_assignments')}
              </Text>
              <Text size="x-small" style={{ color: Colors.GREY_50 }}>
                {t('manage_assignments_detail')}
              </Text>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <CloseIcon
        className="position-absolute cursorPointer"
        color={Colors.LAVENDER}
        onClick={onCancel}
        size={32}
        style={{ right: '20px', top: '20px' }}
      />
    </Card>
  );
};

export default AssignmentWhatsNew;

AssignmentWhatsNew.defaultProps = {
  onCancel: null
};

AssignmentWhatsNew.propTypes = {
  onCancel: PropTypes.func
};
