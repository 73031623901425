import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { AuthenticatedNavbar } from '@components/layoutComponents';
import { Title, Text, Stack, Badge, Colors } from '@design-system';

import { getApiWithAuth, getAssignmentStatus } from '@utils';

import ClassResults from './ClassResults';
import StudentResults from './StudentResults';
import NoAssignmentReport from './NoAssignmentReport';

export const AssignmentReport = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'assignments' });

  const { id } = useParams();

  const [loadingReport, setLoadingReport] = useState(true);
  const [loadingDetail, setLoadingDetail] = useState(true);
  const [reports, setReports] = useState();
  const [detail, setDetail] = useState();

  const getAssignmentReport = async () => {
    const { success, data } = await getApiWithAuth(`school/assignments/${id}/assessments`);
    if (success) {
      setLoadingReport(false);
      setReports(data);
    } else {
      setLoadingReport(false);
    }
  };

  const getAssignmentDetail = async () => {
    const { success, data } = await getApiWithAuth(`school/assignments/${id}`);
    if (success) {
      setLoadingDetail(false);
      setDetail(data);
    } else {
      setLoadingDetail(false);
    }
  };

  useEffect(() => {
    getAssignmentReport();
    getAssignmentDetail();
  }, [id]);

  const assignmentStatus = getAssignmentStatus(detail?.start_date, detail?.end_date, t);
  const assignmentDetail = detail? `${detail?.grade?.name} - ${detail?.curriculum_type} - ${detail?.topic?.name}`  : '';

  return (
    <div className="container-fluid p-2 p-md-3 p-lg-4 overViewMianDivHeight">
      <AuthenticatedNavbar
        smallHeading2={`${t('assign_learning_topics')}`}
        smallHeading3={`${detail ? detail?.name : ''} ${t('report')}`}
      />
        {loadingReport || loadingDetail ? (
          <Stack direction="column" justifyContent="center" style={{ minHeight: '400px' }}>
            <Spin size="large" />
          </Stack>
        ) : !reports || !detail ? (
          <NoAssignmentReport />
        ) : (
          <Row gutter={[16, 16]} style={{ marginTop: 30 }}>
            <Col span={24} md={24} sm={24}>
              <Stack direction="column" spacing={30} alignItems='flex-start'>
                <Title data-testid={`${detail ? detail?.name : ''} ${t('report')}`} level={2}>{`${detail ? detail?.name : ''} ${t('report')}`}</Title>
                <Stack direction="column" alignItems='flex-start'>
                  <Badge type={assignmentStatus?.type}>{assignmentStatus?.status}</Badge>
                  <Stack direction="column" alignItems='flex-start'>
                    <ul style={{ listStyleType: 'disc'}}>
                      <li>
                        <Text size="small">Start: {detail?.start_date} - <span style={ assignmentStatus?.type === 'danger' ? { color: Colors.CHERRY_100} : {}}>End: {detail?.end_date}</span></Text>
                      </li>
                      <li>
                        <Text size="small">{assignmentDetail}</Text>
                      </li>
                    </ul>
                  </Stack>
                </Stack>
              </Stack>
            </Col>
            <Col span={10} md={10} sm={24}>
              <ClassResults detail={detail} reports={reports} />
            </Col>
            <Col span={14} md={14} sm={24}>
              <StudentResults detail={detail} reports={reports} />
            </Col>
          </Row>
        )}
    </div>
  );
};
export default AssignmentReport;
