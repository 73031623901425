import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@design-system';
import { Modal } from 'antd';

import { Document, Page, PDFViewer, Text, View } from '@react-pdf/renderer';
import { getAssignmentPercent } from '@pages/Assignments/common';
import { ResultPdfListItem, resultPdfStyles } from '@pages/Assignments/PdfModals/ResultPDFCommon';

export const ClassResultPdfModal = ({ isOpen, onClose, reports, details, currentClass }) => {
  const today = new Date();
  const formattedDate = today.toISOString().split('T')[0];

  const newReport = reports.map(report => {
    let max_score = 0;
    let score = 0;
    report.assessments.forEach((assessment, i) => {
      score += assessment.score / (i + 1);
      max_score += assessment.max_score / (i + 1);
    });

    const { percent, textColor, backgroundColor } =
      report.assessments.length > 0 ? getAssignmentPercent(score, max_score) : getAssignmentPercent(null, null);

    return { ...report, max_score, score, percent, textColor, backgroundColor };
  });

  return (
    <Modal
      width="80vw"
      closable={false}
      centered
      footer={null}
      onCancel={onClose}
      open={isOpen}
      style={{ padding: 0 }}
      bodyStyle={{ padding: 0 }}>
      <Stack direction="column" alignItems="flex-start" spacing={12}>
        <PDFViewer style={{ width: '100%', height: '90vh' }}>
          <Document title={`${currentClass?.name} - ${details?.name} - ${formattedDate}`}>
            <Page size="A4" style={resultPdfStyles.page}>
              <View style={resultPdfStyles.header}>
                <View style={resultPdfStyles.classRow}>
                  <Text style={resultPdfStyles.className}>{currentClass?.name}</Text>
                  <Text style={resultPdfStyles.todayDate}>{formattedDate}</Text>
                </View>
                <Text style={resultPdfStyles.assignmentName}>{details?.name}</Text>
                <ResultPdfListItem>
                  Start: {details?.start_date} - End: {details?.end_date}
                </ResultPdfListItem>
                <ResultPdfListItem>
                  {details?.grade?.name} - {details?.curriculum_type} - {details?.topic?.name} -{' '}
                  {details?.missions.map(mission => mission.name).join(', ')} and Assessment
                </ResultPdfListItem>
              </View>
              <View style={resultPdfStyles.table}>
                <View style={resultPdfStyles.tableRow}>
                  <View style={[resultPdfStyles.tableHeaderCell, resultPdfStyles.studentCell]}>
                    <Text style={resultPdfStyles.tableHeaderText}>Student</Text>
                  </View>
                  <View style={[resultPdfStyles.tableHeaderCell, resultPdfStyles.outcomeCell]}>
                    <Text style={resultPdfStyles.tableHeaderText}>Outcome</Text>
                  </View>
                  <View style={[resultPdfStyles.tableHeaderCell, resultPdfStyles.resultCell]}>
                    <Text style={resultPdfStyles.tableHeaderText}>Result</Text>
                  </View>
                </View>
                {newReport.map((student, i) => (
                  <View key={i} style={resultPdfStyles.tableRow}>
                    <View style={[resultPdfStyles.tableCell, resultPdfStyles.studentCell]}>
                      <Text style={resultPdfStyles.studentCellText}>{student.user}</Text>
                    </View>
                    <View style={[resultPdfStyles.tableCell, resultPdfStyles.outcomeCell]}>
                      <Text style={resultPdfStyles.studentCellText}>
                        {student.status.toUpperCase() === 'COMPLETED'
                          ? `${student.score}/${student.max_score} correct`
                          : 'Pending'}
                      </Text>
                    </View>
                    <View style={[resultPdfStyles.tableCell, resultPdfStyles.resultCell]}>
                      <View style={resultPdfStyles.resultCellRow}>
                        <Text
                          style={[
                            resultPdfStyles.resultCellText,
                            { color: student.textColor, backgroundColor: student.backgroundColor }
                          ]}>
                          {student.percent} %
                        </Text>
                      </View>
                    </View>
                  </View>
                ))}
              </View>
              <Text
                style={resultPdfStyles.pageNumber}
                render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                fixed
              />
            </Page>
          </Document>
        </PDFViewer>
      </Stack>
    </Modal>
  );
};

ClassResultPdfModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  reports: PropTypes.array.isRequired,
  details: PropTypes.object.isRequired,
  currentClass: PropTypes.object.isRequired
};
