import React, { useState, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Select, Spin, Empty, Row, Col } from 'antd';

import { AuthenticatedNavbar } from '@components/layoutComponents';
import { Pagination } from '@components/commonComponents';
import { API_URL, getApiWithAuth } from '@utils';
import { useGrades } from '@hooks';
import { useAppState } from '@context';
import downArrow from '@assets/images/DownArrow.svg';
import { Button, Form, Link, Stack, Text } from '@design-system';
import ResourceCard from '@components/ResourceCard';

const { Option } = Select;

const InClassResources = () => {
  const { t } = useTranslation('teacher', { keyPrefix: 'teaching_resources' });
  const { t: commonT } = useTranslation('common');
  const [searchParams, setSearchParams] = useSearchParams();

  const { state } = useAppState();
  const currentClass = state.classes.find(item => item.id === state.classId);

  const { data: grades } = useGrades();

  const [skills, setSkills] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [curriculums, setCurriculums] = useState([]);
  const [topicsData, setTopicsData] = useState([]);
  const [filteredTopicsData, setFilteredTopicsData] = useState([]);
  const [unitPlan, setUnitPlan] = useState('');
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [defaultCurriculum, setDefaultCurriculum] = useState(searchParams.get('curriculum') ?? 'All');

  const dataGrade = searchParams.get('grade') ?? currentClass?.grade ?? 'Grade 1';
  const dataSubject = searchParams.get('subject') ?? 'All';
  const dataSkill = searchParams.get('skill') ?? 'All';

  const itemsPerPage = 8;

  const selectGrade = gradeName => {
    setSearchParams(params => {
      params.set('grade', gradeName);
      return params;
    });

    handleReset();
  };

  const selectSubject = subject => {
    setSearchParams(params => {
      params.set('subject', subject);
      return params;
    });

    getUnitPlan(subject);
  };

  const selectSkill = skill => {
    setSearchParams(params => {
      params.set('skill', skill);
      return params;
    });
  };

  const selectCurriculum = curriculum => {
    setSearchParams(params => {
      params.set('curriculum', curriculum);
      return params;
    });
    setDefaultCurriculum(curriculum);
  };

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const getUnitPlan = async subject_name => {
    const subject = subjects.filter(item => item.name === subject_name);

    if (subject.length > 0) {
      const { success, data } = await getApiWithAuth(
        `${API_URL.GET_SCHOOL_SUBJECTS}/${subject[0].id}?grade=${dataGrade}`
      );
      if (success) {
        setUnitPlan(data.unit_plan);
      }
    }
  };

  const getTopics = async () => {
    setButtonSpinner(true);
    const { success, data } = await getApiWithAuth(`${API_URL.SCHOOL_TOPICS}?grade=${dataGrade}`);
    if (success) {
      const curriculumsData = new Set();
      const skillsData = new Set();
      const subjectData = new Set();
      const newData = data.map(topic => {
        topic.curriculums.forEach(curriculum => {
          curriculumsData.add(curriculum.name);
        });

        if (topic.sub_heading) {
          skillsData.add(JSON.stringify({ ...topic.sub_heading, value: topic.sub_heading.name }));
          topic.sub_heading = { ...topic.sub_heading, text: topic.sub_heading.name };
          subjectData.add(JSON.stringify({ ...topic.subject, value: topic.subject.name }));
        }

        return topic;
      });
      setTopicsData(newData);
      setCurriculums(Array.from(curriculumsData).map(curriculum => ({ name: curriculum, value: curriculum })));
      setSkills(Array.from(skillsData).map(skill => JSON.parse(skill)));
      setSubjects(Array.from(subjectData).map(subject => JSON.parse(subject)));
      setButtonSpinner(false);
    } else {
      setButtonSpinner(false);
    }
  };

  const handleReset = () => {
    setSearchParams(params => {
      params.delete('skill');
      params.delete('curriculum');
      return params;
    });
    getTopics();
  };

  useEffect(() => {
    getTopics();
  }, [dataGrade]);

  useEffect(() => {
    getFilterData();
  }, [topicsData, dataSkill, dataSubject, defaultCurriculum]);

  useEffect(() => {
    if (subjects.length > 0) {
      selectSubject(subjects[0].name);
    }
  }, [subjects]);

  // Set the first Curriculum that != "Cyber Legends Curriculum"
  useEffect(() => {
    if (!curriculums.length || searchParams.get('curriculum')) return;
    if (curriculums.length > 1) {
      const filteredCurriculums = curriculums?.filter((item) => item.name !== 'Cyber Legends Curriculum');
      setDefaultCurriculum(filteredCurriculums[0].name);
      setSearchParams(params => {
        params.set('curriculum', filteredCurriculums[0].name);
        return params;
      });
    }
  }, [curriculums]);

  const getFilterData = () => {
    const filteredData = topicsData
      .filter(topic => topic.subject.name === dataSubject || dataSubject === 'All')
      .filter(topic => topic.sub_heading.name === dataSkill || dataSkill === 'All')
      .filter(
        topic =>
          topic.curriculums.filter(curriculum => curriculum.name === defaultCurriculum).length !== 0 ||
          defaultCurriculum === 'All'
      );

    setFilteredTopicsData(filteredData);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(filteredTopicsData.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = useMemo(
    () => filteredTopicsData.slice(indexOfFirstItem, indexOfLastItem),
    [filteredTopicsData, indexOfFirstItem, indexOfLastItem]
  );

  return (
    <>
      <div className="container-fluid p-2 p-md-3 p-lg-4 overViewMianDivHeight">
        <AuthenticatedNavbar
          title={
            <div className="row">
              <div className="col col-12">
                {t('resources_for_grade')}
                <span>
                  <Select
                    suffixIcon={<img src={downArrow} alt="downArrow" />}
                    onChange={selectGrade}
                    value={dataGrade}
                    className="addLineBottom"
                    name="grade">
                    {grades?.map(item => (
                      <Option key={item.name} value={item.name}>
                        {commonT(`${item.name.replace(' ', '_').toLowerCase()}`)}
                      </Option>
                    ))}
                  </Select>
                </span>
              </div>
            </div>
          }
        />
        <Stack direction="column" data-testid="resources-page" alignItems="flex-start" spacing={30} style={{ marginTop: 30 }}>
          <Row>
            <Stack direction="column" alignItems="flex-start">
              <Text size="small">{t('unit_plan_description')}</Text>
              <Link
                onClick={() => {
                  window.open(unitPlan, '_blank');
                }}>
                {t('see_unit_plan')}
              </Link>
            </Stack>
          </Row>
          <Form>
            <Stack style={{ flexWrap: 'wrap' }}>
              <Form.Item data-testid="resources-subject-filter" style={{ flex: '1 1 300px' }}>
                <Select onChange={selectSubject} disabled value={dataSubject} name="grade">
                  <Option key={-1} value={'All'}>
                    {t('select_subject')}
                  </Option>
                  {subjects?.map(item => (
                    <Option key={item.name} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item data-testid="resources-skill-filter" style={{ flex: '1 1 300px' }}>
                <Select onChange={selectSkill} value={dataSkill} name="skill">
                  <Option key={-1} value={'All'}>
                    {t('select_skill')}
                  </Option>
                  {skills?.map(item => (
                    <Option key={item.name} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item data-testid="resources-curriculum-filter" style={{ flex: '1 1 300px' }}>
                <Select onChange={selectCurriculum} value={defaultCurriculum} name="curriculum">
                  <Option key={-1} value={'All'}>
                    {t('select_curriculum')}
                  </Option>
                  {curriculums?.map(item => (
                    <Option key={item.name} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Button data-testid="resources-reset-button" style={{ padding: '14px 25px !important', height: '50px' }} onClick={handleReset}>
                {t('reset')}
              </Button>
            </Stack>
          </Form>
          {buttonSpinner ? (
            <Stack direction="column" justifyContent="center" style={{ minHeight: '300px' }}>
              <Spin size="large" />
            </Stack>
          ) : filteredTopicsData?.length === 0 ? (
            <Stack data-testid="no-resources-found" direction="column" justifyContent="center" style={{ minHeight: '300px' }}>
              <Empty description={false} />
            </Stack>
          ) : (
            <Stack direction="column">
              <Row style={{ width: '100%' }} gutter={[16, 16]}>
                {currentItems?.map((item, index) => {
                  return (
                    <Col lg={6} md={8} sm={12} xs={24} key={`${item.id}-${index}`}>
                      <ResourceCard resourceItem={item} />
                    </Col>
                  );
                })}
              </Row>
              <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
            </Stack>
          )}
        </Stack>
      </div>
    </>
  );
};
export default InClassResources;
