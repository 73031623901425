import React, { useEffect, useState } from 'react';
import { Link, Stack, Title, Text, Button } from '@design-system';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import LessonsCard from '@components/LessonCard';
import LoadingCards from '@components/LoadingCards';
import { API_URL, getApiWithAuth } from '@utils';
import { useAppState } from '@context';

const Lessons = () => {
  const { t } = useTranslation('teacher', { keyPrefix: 'teaching_resources' });
  const navigate = useNavigate();
  const { state } = useAppState();

  const [lessonsData, setLessonsData] = useState([]);
  const [fetchingLessons, setFetchingLessons] = useState(false);
  const showMore = lessonsData?.length > 5;
  const hasLessons = lessonsData?.length > 0;

  const getLessons = async () => {
    setFetchingLessons(true);
    const { success, data } = await getApiWithAuth(`${API_URL.PLANNER_EVENT}/${state?.classId}`);
    if (success) {
      const todayEvent = [...data]?.filter(event => dayjs(event.date).isSame(dayjs(), 'day'));
      setLessonsData(todayEvent);
    }
    setFetchingLessons(false);
  };

  useEffect(() => {
    if(!state?.classId) return;
    getLessons();
  }, [state?.classId]);

  return (
    <Stack data-testid='lessons-overview' direction="column" spacing={10}>
      <Stack justifyContent="space-between">
        <Title level={3}>{t('lessons')}</Title>
        {showMore && <Link onClick={() => navigate('/planner')}>{t('view_all')}</Link>}
      </Stack>
      {fetchingLessons ? (
        <Stack direction="column" spacing={10}>
          <LoadingCards paragraph={{ rows: 1 }} />
        </Stack>
      ) : (
        <>
          {hasLessons && (
            <Stack direction="column" style={{ paddingTop: '10px' }}>
              {lessonsData?.slice(0, 5)?.map((lesson, index) => (
                <LessonsCard key={index} lessonItem={lesson} />
              ))}
            </Stack>
          )}
          {!hasLessons && (
            <Stack direction="column" alignItems="flex-start" spacing={20}>
              <Text size={'small'}>{t('no_lessons_text')}</Text>
              <Button block onClick={() => navigate('/planner')}>
                {t('schedule_a_lesson')}
              </Button>
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
};

export default Lessons;
