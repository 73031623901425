import React, { useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Spin } from 'antd';
import { Helmet } from 'react-helmet-async';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import { AlertTriangle, ArrowUpRight, Codesandbox, Paperclip, Video } from 'react-feather';

import { AuthenticatedNavbar } from '@components/layoutComponents';
import { API_URL, sendGaEvent } from '@utils';

import { AlignmentDetailsModal } from './AlignmentDetailsModal';
import { VideoPlayer } from './VideoPlayer';

import './TeachingResourcesDetail.scss';
import { Button } from '@design-system';
import { useGrades } from '@hooks';
import TeachingResourceCard from '@components/commonComponents/TeachingResourceCard';
import { MissionModal } from '@pages/Assignments/MissionModal';
import { AssignmentQuestionsPdfModal } from '@pages/Assignments/PdfModals';
import { useModal } from '@context/modalContext';
import { EducatorModal } from '@components/commonComponents';

const groupedResources = resources => {
  return resources.reduce((acc, resource) => {
    const { name, resource_type, file_type, url } = resource;
    if (!acc[resource_type]) {
      acc[resource_type] = {
        name,
        resource_type,
        resource_gdrive: null,
        resource_gdrive_url: '',
        resource_pdf: null,
        resource_pdf_url: '',
        resource_document: null,
        resource_document_url: ''
      };
    }

    if (file_type === 'Google Drive') {
      acc[resource_type].resource_gdrive = 'Google Drive';
      acc[resource_type].resource_gdrive_url = url;
    } else if (file_type === 'PDF') {
      acc[resource_type].resource_pdf = 'Pdf';
      acc[resource_type].resource_pdf_url = url;
    } else if (file_type === 'PPTX' || file_type === 'DOCX') {
      acc[resource_type].resource_document = file_type === 'PPTX' ? 'Pptx' : 'Docx';
      acc[resource_type].resource_document_url = url;
    }

    return acc;
  }, {});
};

const downloadPdf = (url, fileName) => {
  fetch(url)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.blob();
    })
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName || 'download.pdf'; // Default filename
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    })
    .catch(error => {
      // eslint-disable-next-line no-console
      console.error('Error downloading PDF:', error);
    });
};

const scrollToHeading = heading => {
  const element = document.getElementById(heading);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }
};

export const TeachingResourcesDetail = () => {
  const { t } = useTranslation('teacher', { keyPrefix: 'teaching_resources' });
  const { id } = useParams();
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setModalContent } = useModal();

  const { data: grades } = useGrades();

  const dataGrade = searchParams.get('grade') || 'Grade 1';

  const { data: topicResponse, isLoading } = useSWR(`${API_URL.SCHOOL_TOPICS}/${id}?grade=${dataGrade}`, {
    revalidateOnFocus: false
  });

  const topic = topicResponse && topicResponse.data ? topicResponse.data : {};
  const videos = topic?.videos ?? [];

  const title = topic ? topic.name : [];
  const curriculums = topic ? topic.curriculums : [];
  const resources = Object.values(groupedResources(topic && topic.resources ? topic.resources : []));
  const subHeading = topic?.sub_heading;

  const hasTeachingResources = resources.length > 0;
  const isGameLearningMissions = topic?.missions?.length > 0;
  const hasVideos = videos.length > 0;
  const hasAssessments = topic?.assessments?.length > 0;

  const [showAlignmentModal, setShowAlignmentModal] = useState(false);
  const [missionPreview, setMissionPreview] = useState(null);
  const [assessmentPreview, setAssessmentPreview] = useState(null);

  return (
    <>
      <Helmet>
        <title>{title ? `${t('teaching_resources')}: ${title} - ` : ''}Cyber Legends</title>
      </Helmet>
      {isLoading ? (
        <div className="spineerCenter">
          <Spin size="large" />
        </div>
      ) : (
        <div className="resources-detail__container">
          <div className="resources-detail__main">
            <div className="resources-detail__header">
              <div className="container">
                <AuthenticatedNavbar smallHeading1={`${t('in_class')}`} smallHeading2={`${topic.name}`} />
                <div className="row mt-3 mb-3">
                  <div className="col-12 col-md-11 col-lg-10 ">
                    <div className="row mt-3 mb-3">
                      <div className="col-2">
                        {topic.sub_heading && (
                          <img src={topic.sub_heading.thumbnail} alt="Badge" className="img-fluid" />
                        )}
                      </div>
                      <div className="col-10">
                        <div className="overview__container">
                          <h3 className="resources-detail__heading--sub mt-3">{topic.name}</h3>
                          <div className="resources-detail__text--sub">{topic.description}</div>
                          <div className="resources-detail-content mt-3">
                            {hasTeachingResources && (
                              <div className="resources-detail-content__item">
                                <Button type="text" onClick={() => scrollToHeading('teaching_resources')}>
                                  <Paperclip size={15} /> {t('teaching_resources')}
                                </Button>
                              </div>
                            )}
                            {isGameLearningMissions && (
                              <div className="resources-detail-content__item">
                                <Button type="text" onClick={() => scrollToHeading('game_learning_missions')}>
                                  <Codesandbox size={15} /> {t('game_learning_missions')}
                                </Button>
                              </div>
                            )}
                            {hasVideos && (
                              <div className="resources-detail-content__item">
                                <Button type="text" onClick={() => scrollToHeading('video_resources')}>
                                  <Video size={15} /> {t('video_resources')}
                                </Button>
                              </div>
                            )}
                          </div>
                          <div className="resources-detail-category mt-3">
                            <div className="resources-detail-category__item">{dataGrade}</div>
                            {state?.curriculums?.map(curriculum => (
                              <div key={curriculum.name} className="resources-detail-category__item">
                                {curriculum.name}
                              </div>
                            ))}
                            {subHeading && (
                              <div
                                className="resources-detail-category__item"
                                style={{ backgroundColor: subHeading.background_color, color: subHeading.text_color }}>
                                {subHeading.name}
                              </div>
                            )}
                            {state?.isSensitive && (
                              <div className="resources-detail-category__item sensitive">
                                <AlertTriangle size={15} color={'#D13E32'} /> {t('sensitive_content')}
                              </div>
                            )}
                          </div>
                          <div className="mt-4">
                            <Button
                              type="secondary"
                              onClick={() => {
                                navigate(
                                  `/assignments/new?topic_id=${id}&grade_id=${
                                    grades.find(item => item.name === dataGrade).id
                                  }`
                                );
                              }}>
                              {t('create_assignment')}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-7">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-md-12 mr-auto">
                        <div className="related-resources__container mt-5">
                          <h3 id="teaching_resources" className="resources-detail__heading--sub">
                            {t('teaching_resources')}
                          </h3>
                          {resources?.map(item => (
                            <div key={`${item.name}-${new Date().getTime()}`}>
                              <TeachingResourceCard
                                color={subHeading.background_color}
                                title={item.resource_type}
                                resourcePdf={item.resource_pdf}
                                downloadResourcePdf={type => {
                                  sendGaEvent(
                                    type === 'open' ? 'resource_preview' : 'resource_download',
                                    `Resource ${type === 'open' ? 'Preview' : 'Download'} (${item.resource_type})`,
                                    item.name
                                  );
                                  if (type === 'open') {
                                    setModalContent(
                                      <EducatorModal
                                        visible
                                        width="80%"
                                        height="80%"
                                        onCancel={() => {
                                          setModalContent(null);
                                        }}>
                                        <div className="container-fluid h-100">
                                          <iframe
                                            src={item.resource_pdf_url}
                                            title={item.name}
                                            style={{
                                              height: '100%',
                                              width: '100%'
                                            }}
                                          />
                                        </div>
                                      </EducatorModal>
                                    );
                                  } else {
                                    downloadPdf(item.resource_pdf_url, item.name);
                                  }
                                }}
                                resourceGoogle={item.resource_gdrive}
                                downloadResourceGoogle={type => {
                                  sendGaEvent(
                                    type === 'open' ? 'resource_preview' : 'resource_download',
                                    `Resource ${type === 'open' ? 'Preview' : 'Download'} (${item.resource_type})`,
                                    item.name
                                  );
                                  window.open(
                                    type === 'open'
                                      ? item.resource_gdrive_url
                                      : item.resource_gdrive_url + '/export?gid=0&format=pdf',
                                    '_blank'
                                  );
                                }}
                                resourceDocument={item.resource_document}
                                downloadResourceDocument={type => {
                                  sendGaEvent(
                                    type === 'open' ? 'resource_preview' : 'resource_download',
                                    `Resource ${type === 'open' ? 'Preview' : 'Download'} (${item.resource_type})`,
                                    item.name
                                  );
                                  window.open(item.resource_document_url, '_blank');
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    {isGameLearningMissions && (
                      <div className="row mt-4">
                        <h3 id="game_learning_missions" className="resources-detail__heading--sub mt-3">
                          {t('game_learning_missions')}
                        </h3>
                        <div className="missions-list">
                          <ul>
                            {topic.missions.map(mission => (
                              <li key={mission.id}>
                                <div>
                                  <img src={mission.thumbnail} alt={mission.name} />
                                  <div className="missions-list__title">{mission.name}</div>
                                  <div className="missions-list__action">
                                    <button
                                      className="text__link"
                                      onClick={() => {
                                        setMissionPreview(mission.id);
                                      }}>
                                      {t('try_the_game')} <ArrowUpRight size={15} color={'#674EFF'} />
                                    </button>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                    {hasAssessments && (
                      <div className="row mt-4">
                        <div className="related-resources__container">
                          {topic.assessments.map(assessment => (
                            <div key={assessment.id}>
                              <TeachingResourceCard
                                color={subHeading.background_color}
                                title={assessment.name}
                                resourcePdf="PDF"
                                downloadResourcePdf={type => {
                                  if (type === 'open') {
                                    sendGaEvent('assessment_preview', 'Assessment Preview', assessment.name);
                                  } else {
                                    sendGaEvent('assessment_download', 'Assessment Download', assessment.name);
                                  }

                                  setAssessmentPreview(assessment.id);
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {videos.length > 0 && (
                      <div className="row mt-5">
                        <h3 id="video_resources" className="resources-detail__heading--sub mt-3">
                          {t('videos_to_play_in_class')}
                        </h3>
                        <div>
                          <VideoPlayer videos={videos} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-1" />
                <div className="col-12 col-lg-4">
                  <div>
                    <h3 className="resources-detail__heading--sub mt-5">{t('aligned_curriculums')}</h3>
                    <div className={'curriculum-list'}>
                      <ul>
                        {curriculums.map(curriculum => (
                          <li key={curriculum.name}>{curriculum.name}</li>
                        ))}
                      </ul>
                      <button
                        className="text__link"
                        onClick={() => {
                          sendGaEvent('view_alignment_details', 'View Alignment Details', topic.name);
                          setShowAlignmentModal(true);
                        }}>
                        {`${t('view_alignment_details')}  >`}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <AlignmentDetailsModal
            curriculums={topic.curriculums}
            show={showAlignmentModal}
            handleOnCancel={() => setShowAlignmentModal(false)}
          />

          {assessmentPreview && topic && (
            <AssignmentQuestionsPdfModal
              isOpen
              onClose={() => {
                setAssessmentPreview(null);
              }}
              topic={topic}
              grade={grades.find(item => item.name === dataGrade)}
              assessmentId={assessmentPreview}
            />
          )}

          {missionPreview && topic && (
            <MissionModal
              show
              missionId={missionPreview}
              handleOnCancel={() => {
                setMissionPreview(null);
              }}
            />
          )}
        </div>
      )}
    </>
  );
};

export default TeachingResourcesDetail;
