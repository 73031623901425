import React, { useEffect, useState } from 'react';
import { Image, Select, Spin, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useAppState } from '@context';
import { Card, Link, Table, Title, Text, Stack, Form, Badge, Colors } from '@design-system';

import { QuestionCircleFilled } from '@ant-design/icons';
import NotFoundImage from '@assets/images/icon_researchPaperBlack.svg';
import { getAssignmentPercent } from '@pages/Assignments/common';
import { getApiWithAuth } from '@utils';
import { IndividualResultPdfModal } from '@pages/Assignments/PdfModals';

const { Option } = Select;

const columns = t => [
  {
    title: (
      <>
        {t('learning_goals')}&nbsp;
        <Tooltip color="purple" title={t('learning_goal_tooltip')}>
          <QuestionCircleFilled style={{ color: '#D4DFFC', fontSize: '16px' }} />{' '}
        </Tooltip>
      </>
    ),
    dataIndex: 'learning_goal',
    key: 'learning_goal',
  },
  {
    title: t('outcome'),
    dataIndex: 'outcome',
    key: 'outcome',
    width: 120,
    render: (_, record) => (`${record?.score}/${record?.max_score}`),
  },
  {
    title: t('result'),
    dataIndex: 'result',
    key: 'result',
    width: 90,
    render: (_, record) => {
      const { percent } = getAssignmentPercent(record?.score, record?.max_score);

      return (
        <Stack>
          <Badge type={percent < 40 ? 'danger' : percent < 80 ? 'warning' : 'success'}>{`${percent}%`}</Badge>
        </Stack>
      );
    }
  }
];

const StudentResults = ({ detail, reports }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'assignments' });
  const { state } = useAppState();

  const [showIndividualResultPdf, setShowIndividualResultPdf] = useState(false);
  const [student, setStudent] = useState();
  const [studentProgress, setStudentProgress] = useState('All');
  const [loadingStudent, setLoadingStudent] = useState(false);

  const currentClass = state.classes.find(item => item.id === state.classId);

  useEffect(async () => {
    if (studentProgress !== 'All') {
      setLoadingStudent(true);
      const { success, data } = await getApiWithAuth(`/school/assessments/progress/${studentProgress}`);
      if (success) {
        setLoadingStudent(false);
        setStudent(data);
      } else {
        setLoadingStudent(false);
      }
    }
  }, [studentProgress]);

  const selectStudent = studentId => {
    setStudentProgress(studentId);
  };

  const {
    percent: studentPercent,
  } = student ? getAssignmentPercent(student?.score, student?.max_score) : getAssignmentPercent(null, null);

  return (
    <>
      <Card data-testid='no-assignment-report'>
        <Stack direction="column" alignItems="flex-start">
              <Stack style={{ paddingBottom: 20 }} direction="column" alignItems="flex-start" spacing={30}>
                <Form>
                  <Stack>
                    <Title level={3} style={{ whiteSpace: 'nowrap' }}>{t('result_for')}</Title>
                  <Form.Item data-testid='no-assignment-report'>
                    <Select
                      onChange={selectStudent}
                      value={studentProgress}
                      optionLabelProp="label">
                      <Option key={-1} label={t('select_student_placeholder')} value={'All'}>
                        {t('select_student_placeholder')}
                      </Option>
                      {reports?.filter(assignmentProgress => assignmentProgress?.assessments?.length > 0)
                          .map(item => (
                          <Option
                            style={{ borderBottom: `2px solid ${Colors.LAVENDER_50}` }}
                            key={item?.id}
                            label={item?.user}
                            value={item?.assessments?.[0]?.id}>
                            <Stack justifyContent="space-between" style={{ padding: '10px 20px' }}>
                              {item?.user}
                              <Badge type='invalid'>{item?.grade}</Badge>
                            </Stack>
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                  </Stack>
                </Form>
                <Text size="small">
                  {t('select_student_for_view')}&nbsp;
                  <Link
                    data-testid='assignment-print'
                    style={{ display: 'inline-block' }}
                    onClick={() => {
                    if (student) {
                      setShowIndividualResultPdf(true);
                    }
                  }}>
                    {t('print')}
                  </Link>
                  {t('their_report')}
                </Text>
                {loadingStudent ? (
                  <Stack style={{ paddingTop: 40, minHeight: 160 }} justifyContent="center">
                    <Spin size="large" />
                  </Stack>
                ) : !student ? (
                  <Stack direction="column" justifyContent="center">
                    <Image src={NotFoundImage} preview={false} height={140} />
                    <Title style={{ textAlign: 'center' }} level={3}>
                      {t('no_student_selected')}
                    </Title>
                    <Text style={{ textAlign: 'center' }} size="small">
                      {t('no_student_selected_detail')}
                    </Text>
                  </Stack>
                ) : (
                  <Stack direction='column' spacing={30}>
                    <Stack justifyContent='space-between'>
                      <Title style={{ whiteSpace: 'nowrap' }} level={5}>{t('overall_result')}:</Title>
                      <Stack justifyContent='flex-end' spacing={30}>
                        <Text data-testid='asssignment-score' size='x-small' bold>{student?.score}/{student?.max_score} Correct</Text>
                        <Badge type={studentPercent < 40 ? 'danger' : studentPercent < 80 ? 'warning' : 'success'}>{studentPercent}%</Badge>
                      </Stack>
                    </Stack>
                  <Table
                    data-testid='student-results'
                    columns={columns(t)}
                    dataSource={student ? student?.results : []}
                    pagination={{ position: ['bottomCenter'], defaultPageSize: 20, hideOnSinglePage: true }}
                  />
                  </Stack>
                )}
              </Stack>
        </Stack>
      </Card>
      {showIndividualResultPdf && student && (
        <IndividualResultPdfModal
          student={student}
          details={detail}
          currentClass={currentClass}
          isOpen={showIndividualResultPdf}
          onClose={() => setShowIndividualResultPdf(false)}
        />
      )}
    </>
  );
};

export default StudentResults;

StudentResults.propTypes = {
  detail: PropTypes.object.isRequired,
  reports: PropTypes.array.isRequired,
};