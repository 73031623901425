import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { Calendar, EventTypes, Form, Modal, Stack, Title } from '@design-system';
import { useGrades, usePlannerSettings } from '@hooks';
import { useAppState } from '@context';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { API_URL, getApiWithAuth, postApiWithAuth } from '@utils';
import i18n from '@services/i18n';

const lang = {
  'en-CA': null,
  'en-US': null,
  'fr-CA': {
    week: 'La semaine',
    work_week: 'Semaine de travail',
    day: 'Jour',
    month: 'Mois',
    previous: 'Antérieur',
    next: 'Prochain',
    today: "Aujourd'hui",
    agenda: 'Ordre du jour',
    showMore: total => `+${total} plus`
  }
};

const { Option } = Select;

const PlannerPreview = ({ show, onCancel, setEvents, data: calendarData }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'planner' });
  const { state } = useAppState();
  const [form] = Form.useForm();
  const [culture] = useState(i18n.language);
  const [loading, setLoading] = useState(true);

  const { data: grades } = useGrades();
  const currentClass = state.classes.find(item => item.id === state.classId);
  const gradeId = (grades || [])?.find(g => g.name === currentClass.grade)?.id;

  const { data: settingData, revalidate: getSettings, settingLoading } = usePlannerSettings(state.classId, gradeId);

  const [myEvents, setMyEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [classes, setClasses] = useState([]);
  const [eventsMap, setEventsMap] = useState(new Map());

  const [holidays, setHolidays] = useState();

  const moveEvent = useCallback(
    ({ event, start, end }) => {
      setMyEvents(prev => {
        const existing = prev.find(ev => ev.id === event.id) ?? {};
        const filtered = prev.filter(ev => ev.id !== event.id);
        return [...filtered, { ...existing, start, end, date: start }];
      });
    },
    [setMyEvents]
  );

  useEffect(async () => {
    if (!calendarData) return;

    getSettings();
    setLoading(true);
    const { success, data: eventsData } = await postApiWithAuth(
      `${API_URL.PLANNER_PREVIEW}/${state.classId}`,
      calendarData
    );
    if (success) {
      if (eventsData) {
        const updatedEvents = eventsData.map(event => ({
          title: `${event.topic_name}: ${event.lesson_name}`,
          allDay: true,
          start: dayjs(event.date, 'YYYY-MM-DD'),
          end: dayjs(event.date, 'YYYY-MM-DD'),
          date: dayjs(event.date, 'YYYY-MM-DD'),
          isDraggable: true,
          isEvent: EventTypes.EDITABLE,
          duration: t('all_day'),
          ...event
        }));
        setMyEvents(updatedEvents);
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [calendarData, state.classId]);

  useEffect(() => {
    if (settingData) {
      const updatedSettings = settingData['off_days'].map(holiday => ({
        title: `${holiday.title}: ${holiday.title}`,
        allDay: true,
        start: dayjs(holiday.date, 'YYYY-MM-DD'),
        end: dayjs(holiday.date, 'YYYY-MM-DD'),
        date: dayjs(holiday.date, 'YYYY-MM-DD'),
        isDraggable: false,
        isEvent: EventTypes.HOLIDAY,
        duration: t('all_day'),
        ...holiday
      }));
      setHolidays(updatedSettings);
    }
  }, [settingData]);

  const fetchEvents = async classId => {
    setLoading(true);
    const { success, data: eventsData } = await getApiWithAuth(`${API_URL.PLANNER_EVENT}/${classId}`);
    if (success) {
      if (eventsData) {
        const updatedEvents = eventsData.map(event => ({
          title: `${event.topic_name}: ${event.lesson_name}`,
          allDay: true,
          start: dayjs(event.date, 'YYYY-MM-DD'),
          end: dayjs(event.date, 'YYYY-MM-DD'),
          date: dayjs(event.date, 'YYYY-MM-DD'),
          isDraggable: false,
          isEvent: EventTypes.NON_EDITABLE,
          duration: t('all_day'),
          ...event
        }));
        const newMap = new Map(eventsMap);
        newMap.set(classId, updatedEvents);
        setEventsMap(newMap);
      }
      setLoading(false);
    } else {
      const newMap = new Map(eventsMap);
      newMap.set(classId, []);
      setEventsMap(newMap);
      setLoading(false);
    }
  };

  const { messages } = useMemo(
    () => ({
      messages: lang[culture]
    }),
    [culture]
  );

  useEffect(() => {
    if (myEvents.length > 0) {
      setEvents(myEvents);
    }
  }, [myEvents]);

  const handleClassesChange = values => {
    if (values.length > classes.length) {
      const newItems = values.filter(item => !classes.find(cls => cls === item));
      setClasses(values);
      for (const item of newItems) {
        fetchEvents(item);
      }
    } else {
      const keysSet = new Set(values);

      const newMap = new Map();
      for (const [key, value] of eventsMap) {
        if (keysSet.has(key)) {
          newMap.set(key, value);
        }
      }

      setEventsMap(newMap);
      setClasses(values);
    }
  };

  useEffect(() => {
    setAllEvents(Array.from(eventsMap.values()).flat());
  }, [eventsMap]);

  return (
    <Modal
      data-testid="planner-preview-modal"
      centered={false}
      width="80%"
      background="#F1F5F7"
      style={{ top: 20 }}
      visible={show}
      onCancel={onCancel}>
      <Stack direction="column" alignItems="flex-start" spacing={30}>
        <Title level={2}>{t('planner_preview')}</Title>
        <Form form={form}>
          <Form.Item data-testid="planner-select-class" label={t('select_class')}>
            <Select
              mode="multiple"
              value={classes}
              placeholder="Select Other Classs"
              optionLabelProp="label"
              onChange={handleClassesChange}>
              {state.classes
                .filter(v => state.classId !== v.id)
                .map(cls => {
                  return (
                    <Option key={cls.id} value={cls.id} label={cls.name}>
                      {cls.name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Form>
        {loading || settingLoading ? (
          <Stack direction="column" justifyContent="center" style={{ minHeight: '70vh' }}>
            <Spin size="large" />
          </Stack>
        ) : (
          holidays &&
          myEvents &&
          allEvents && (
            <Stack style={{ height: '800px' }}>
              <Calendar
                culture={culture}
                messages={messages}
                events={[...myEvents, ...holidays, ...allEvents]}
                onEventDrop={moveEvent}
                popup
              />
            </Stack>
          )
        )}
      </Stack>
    </Modal>
  );
};

PlannerPreview.defaultProps = {
  data: null
};

PlannerPreview.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  data: PropTypes.any,
  setEvents: PropTypes.func.isRequired
};

export default PlannerPreview;
