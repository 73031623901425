import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Text, Colors } from '@design-system';

import { getDataTestId } from '@utils';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const StyledColor = styled.div`
  padding-left: 10px;
  min-height: 73px;
  height: 100%;
  background-color: ${props => props.backgroundcolor};
`;

const StyledCard = styled.div`
  border-radius: 10px;
  overflow: hidden;
  display: block;
  min-height: 73px;
  width: 100%;
  align-items: flex-start;
  cursor: pointer;
  padding: 0;
  padding-right: 22px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08);
  background-color: ${Colors.WHITE_0};
`;

const LessonsCard = React.memo(({ lessonItem }) => {
  const { lesson_name, topic_name } = lessonItem;
  const navigate = useNavigate();

  return (
    <StyledCard onClick={() => navigate('/planner')} data-testid={getDataTestId(topic_name)}>
      <Stack spacing={20}>
        <StyledColor backgroundcolor={Colors.SKY} />
        <Stack spacing={0} direction="column" alignItems="flex-start" style={{ padding: '14px 0' }}>
          <Text size="small" bold ellipsis>
            {topic_name}
          </Text>
          <Text size="x-small" ellipsis>
            {lesson_name}
          </Text>
        </Stack>
      </Stack>
    </StyledCard>
  );
});

export default LessonsCard;

LessonsCard.propTypes = {
  lessonItem: PropTypes.object.isRequired
};
