import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Layout, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import OnboardingFlow from '@pages/OnboardingFlow';
import { Stack } from '@design-system';
import FeatureWalkthrough from '@components/FeatureWalkthrough';
import AddClassModal from '@components/AddClassModal';
import { useAppState } from '@context';
import { SET_CLASSES, SET_CURRENT_CLASS_ID } from '@context/context-actions';
import { API_URL, getApiWithAuth, getLocalStorageItem, USER_ROLE } from '@utils';

import Footer from '../Footer';
import { SidebarMenu } from './SidebarMenu';
import selectArrow from '../../../assets/images/selectArrow.svg';
import logo from '../../../assets/images/sideBarLogo.svg';
import './SidebarStyle.scss';
import { useSchoolTutorial } from '@hooks';

const { Content, Sider } = Layout;
const { Option } = Select;

const Sidebar = ({ children }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'nav' });
  const { state, dispatch } = useAppState();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: schoolTutorial, loading } = useSchoolTutorial('teacher-tour');

  const [selectedMenuItem, setSelectedMenuItem] = useState('Overview');
  const [showAddClassModal, setShowAddClassModal] = useState(false);
  const [isGetClassesLoading, setIsGetClassesLoading] = useState(false);
  const [showOnboardingFlow, setShowOnboardingFlow] = useState(false);
  const [showFeatureWalkthrough, setShowFeatureWalkthrough] = useState(false);
  const [openKey, setOpenSubMenuKey] = useState();
  const shouldShowFeatureWalkthrough = schoolTutorial?.data?.progress?.status === 'STARTED';

  const currentClass = state.classes.find(item => item.id === state.classId);

  const getTeachersClasses = async () => {
    setIsGetClassesLoading(true);
    const { success, data } = await getApiWithAuth(API_URL.GET_TEACHERS_CLASSES);
    if (success && data.length > 0) {
      dispatch({
        type: SET_CLASSES,
        payload: data
      });
    }
    setIsGetClassesLoading(false);
  };

  const componentsSwitch = event => {
    setSelectedMenuItem(event.key);

    const routeMap = {
      Overview: '/dashboard',
      Students: '/students',
      Assignments: '/assignments',
      InClass: '/resources',
      Edubytes: '/edubytes',
      Activities: '/activities',
      MembershipsandBilling: '/memberships-and-billing',
      ChildAccounts: '/child-accounts',
      Subscriptions: '/subscriptions',
      Support: '/support',
      Planner: '/planner',
      ReferralsCredits: '/referrals-credits'
    };

    navigate(routeMap[event.key] || '/referrals');
  };

  const locationToMenuMap = {
    Students: ['/students'],
    Assignments: ['/assignments'],
    InClass: ['/resources'],
    Edubytes: ['/edubytes'],
    Activities: ['/activities', '/parent-resource'],
    Referrals: ['/referrals'],
    ReferralsCredits: ['/referrals-credits'],
    Subscriptions: [
      '/memberships-and-billing',
      '/manage-subscription',
      '/make-payment',
      '/update-card',
      '/edit-account',
      '/subscriptions',
      'edit-membership'
    ],
    ChildAccounts: ['/child-accounts', '/edit-child-account'],
    Support: ['/support', 'glossary-terms'],
    Planner: ['/planner', '/planner/new', '/planner/customize']
  };

  const updateSideBarMenu = location => {
    let selectedMenuItem = 'Overview';
    for (const menuItem in locationToMenuMap) {
      if (
        locationToMenuMap[menuItem].some(
          path => location.pathname === path || (path.includes('/') && location.pathname.includes(path))
        )
      ) {
        selectedMenuItem = menuItem;
      }
    }

    setSelectedMenuItem(selectedMenuItem);
  };

  const selectClass = selectedClass => {
    if (selectedClass === 'newClass') {
      setShowAddClassModal(true);
      return;
    }

    const value = state.classes.find(item => item.id === selectedClass);
    dispatch({
      type: SET_CURRENT_CLASS_ID,
      payload: value.id
    });
  };

  const handleAddClassClose = async fetchClasses => {
    if (fetchClasses) {
      await getTeachersClasses();
    }
    setShowAddClassModal(false);
  };

  useEffect(() => {
    updateSideBarMenu(location);
  }, [location.pathname]);

  useEffect(() => {
    const fetchClasses = async () => {
      if (getLocalStorageItem('user_role') === USER_ROLE.TEACHER) {
        await getTeachersClasses();
      }
    };

    fetchClasses();
  }, []);

  useEffect(() => {
    if (state.classes.length === 0 || state.classes.length > 1) return;
    if (shouldShowFeatureWalkthrough && !showAddClassModal) {
      setShowFeatureWalkthrough(true);
    }
  }, [shouldShowFeatureWalkthrough, showAddClassModal, state.classes]);

  const value = {
    showAddClass: () => setShowAddClassModal(true),
    isGetClassesLoading: isGetClassesLoading || loading,
    setShowOnboardingFlow
  };

  return (
    <>
      <Layout
        style={{
          minHeight: '100vh'
        }}>
        <Layout className="mainDivParentResourcesStyle" hasSider>
          <Sider className="backgroundColor pt-4 fixedSidebar">
            <Stack direction="column" spacing={0} style={{ height: '100%' }}>
              <div className="logoStyle my-2">
                <img src={logo} alt="cyberLegendLogo" width="70%" />
              </div>
              <hr className="lineStyle w-100" />
              {getLocalStorageItem('user_role') === USER_ROLE.TEACHER && state.classes.length > 0 ? (
                <>
                  <div className="selectTextMain">
                    <Select
                      className="p-2 selectStyleColor capital first-step"
                      onChange={selectClass}
                      popupClassName="borderChange"
                      value={{ label: currentClass?.name, value: currentClass?.id }}
                      suffixIcon={<img src={selectArrow} alt="selectArrow" />}>
                      {state.classes.map(item => (
                        <Option key={item.id} value={item.id} className="siderbarAuthenticated">
                          {item.name}
                        </Option>
                      ))}
                      <Option key="new" value="newClass" className="siderbarAuthenticated">
                        + {t('add_new_class')}
                      </Option>
                    </Select>
                  </div>
                  <hr className="lineStyle my-2 w-100" />
                </>
              ) : null}
              <div className="selectTextMain w-100" style={{ flex: '1 1 0%' }}>
                {getLocalStorageItem('user_role') === USER_ROLE.TEACHER && state.classes.length === 0 ? null : (
                  <SidebarMenu selectedKeys={selectedMenuItem} openKey={openKey} onClick={componentsSwitch} />
                )}
              </div>
            </Stack>
          </Sider>
          <Layout className="site-layout" style={{ marginLeft: '230px' }}>
            <Content className="marginContent">
              <div className="site-layout-background">
                {typeof children === 'function' ? children(value) : children}
              </div>
            </Content>
          </Layout>
        </Layout>
        <Footer />
        <OnboardingFlow
          isOpen={showOnboardingFlow}
          onClose={() => setShowOnboardingFlow(false)}
          showAddClass={() => setShowAddClassModal(true)}
        />
        <FeatureWalkthrough
          isOpen={showFeatureWalkthrough}
          onClose={() => setShowFeatureWalkthrough(false)}
          setOpenSubMenuKey={setOpenSubMenuKey}
        />
      </Layout>
      <AddClassModal isOpen={showAddClassModal} onClose={handleAddClassClose} />
    </>
  );
};
export default Sidebar;

Sidebar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.node]).isRequired
};
