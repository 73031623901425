import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Stack, Text, Title } from '@design-system';
import LogOffIcon from '@assets/images/icon_logoff.png';
import { Image, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { clearStorage, getIsGoogle, GOOGLE_SSO_LOGOUT_URL, setLogoutUrl } from '@utils';
import { Auth } from 'aws-amplify';

const LogoutModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'common' });

  const logoutUser = async () => {
    const isGoogle = getIsGoogle();
    if (isGoogle === '1') {
      clearStorage();
      window.location.href = GOOGLE_SSO_LOGOUT_URL;
    } else {
      await Auth.signOut()
        .then(() => {
          setLogoutUrl('/');
          clearStorage();
        })
        .catch(err => {
          message.error(`${err.message}`);
        });
    }
    onClose();
  };

  return (
    <Modal data-testid='logout-modal' centered={false} onCancel={onClose} open={isOpen}>
      <Stack direction="column" spacing={50}>
        <Stack direction="column" spacing={22}>
          <Image src={LogOffIcon} preview={false} />
          <Stack direction="column" alignItems="center" spacing={20}>
            <Title style={{ textAlign: 'center' }} level={3}>
              {t('sign_out')}
            </Title>
            <Text className="text-center">{t('sign_out_description')}</Text>
          </Stack>
        </Stack>
        <Stack justifyContent="center">
          <Button data-testid='logout-confirm-button' block onClick={logoutUser}>
            {t('sign_out')}
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default LogoutModal;

LogoutModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};
