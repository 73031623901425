import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@design-system';
import { API_URL, postApiWithAuth } from '@utils';
import OnboardingTour from '@pages/OnboardingFlow/OnboardingTour';
import AddClassPrompt from '@pages/OnboardingFlow/AddClassPrompt';
import { useSchoolTutorial } from '@hooks';
import { message } from 'antd';

const OnboardingFlow = ({ isOpen, onClose, showAddClass }) => {
  const { data: schoolTutorial, revalidate } = useSchoolTutorial('teacher-tour');
  const [loading, setLoading] = useState(false);
  const hasCompletedTour = schoolTutorial?.data?.progress?.status === 'STARTED';

  const updateProgress = async () => {
    setLoading(true);
    const { data, success } = await postApiWithAuth(
      API_URL.POST_SCHOOL_TUTORIALS_PROGRESS.replace(':slug', 'teacher-tour'),
      {
        origin: 'Teacher dashboard product tour',
        progress: 50,
        status: 'STARTED'
      }
    );
    if (success) {
      revalidate();
      onClose();
      showAddClass(true);
    } else {
      message.error(data.message);
    }
    setLoading(false);
  };

  const handleClose = async () => {
    if (!hasCompletedTour) {
      await updateProgress();
      return;
    }
    onClose();
    showAddClass(true);
  };

  return (
    <>
      <Modal data-testid='onboarding-flow-modal' width={687} closable={false} maskClosable={false} open={isOpen}>
        {hasCompletedTour ? (
          <AddClassPrompt onClose={handleClose} />
        ) : (
          <OnboardingTour onClose={handleClose} loading={loading} />
        )}
      </Modal>
    </>
  );
};

export default OnboardingFlow;

OnboardingFlow.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  showAddClass: PropTypes.func.isRequired
};
