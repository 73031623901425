import React, { useEffect, useState } from 'react';
import { Image, Input, Spin, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useAppState } from '@context';
import { Search as SearchIcon } from 'react-feather';
import { Card, Link, Table, Title, Text, Stack, Form, Badge, Colors } from '@design-system';

import { QuestionCircleFilled } from '@ant-design/icons';
import BabyImage from '@assets/images/educatorTeacherImage.png';
import { getAssignmentPercent } from '@pages/Assignments/common';
import { ClassResultPdfModal } from '@pages/Assignments/PdfModals';

const columns = t => [
  {
    title: t('student'),
    dataIndex: 'user',
    key: 'student'
  },
  {
    title: (
      <>
        {t('outcome')}&nbsp;
        <Tooltip color="purple" title={t('outcome_tooltip')}>
          <QuestionCircleFilled style={{ color: '#D4DFFC', fontSize: '16px' }} />{' '}
        </Tooltip>
      </>
    ),
    dataIndex: 'outcome',
    key: 'outcome',
    render: (_, record) => {
      let max_score = 0;
      let score = 0;
      record?.assessments?.forEach((assessment, i) => {
        score += assessment?.score / (i + 1);
        max_score += assessment?.max_score / (i + 1);
      });
      return record?.status?.toUpperCase() === 'COMPLETED' ? `${score} / ${max_score}` : 'Pending';
    }
  },
  {
    title: t('result'),
    dataIndex: 'result',
    key: 'result',
    render: (_, record) => {
      const { percent } =
        record?.assessments?.length > 0
          ? getAssignmentPercent(record?.assessments[0]?.score, record?.assessments[0]?.max_score)
          : getAssignmentPercent(null, null);

      return (
        <Stack>
          <Badge data-testid='badge-results' type={percent < 40 ? 'danger' : percent < 80 ? 'warning' : 'success'}>{`${percent}%`}</Badge>
        </Stack>
      );
    }
  }
];

const ClassResults = ({ detail, reports }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'assignments' });
  const { state } = useAppState();

  const [showClassResultPdf, setShowClassResultPdf] = useState(false);
  const [filteredReport, setFilteredReport] = useState();
  const [searchKey, setSearchKey] = useState('');

  const currentClass = state?.classes?.find(item => item.id === state.classId);

  const handleSearch = e => {
    const { value } = e.target;
    setSearchKey(value);
  };

  useEffect(async () => {
    if (reports) {
      if (searchKey !== '') {
        setFilteredReport(reports?.filter(report => report?.user?.toLowerCase().includes(searchKey?.toLowerCase())));
      } else {
        setFilteredReport(reports);
      }
    }
  }, [searchKey, reports]);

  return (
    <>
      <Card>
        <Stack direction="column" alignItems="flex-start">
          <Title level={3}>{t('class_result')}</Title>
          {!reports || !filteredReport ? (
            <Stack style={{ paddingTop: 40, minHeight: 160 }} justifyContent="center">
              <Spin size="large" />
            </Stack>
          ) : (
              <Stack style={{ paddingBottom: 20 }} direction="column" alignItems="flex-start" spacing={30}>
                <Text size="small">
                  {t('view')}&nbsp;
                  <Link
                    style={{ display: 'inline-block' }}
                    onClick={() => {
                      setShowClassResultPdf(true);
                    }}>
                    {t('print')}
                  </Link>
                  {t('your_class_report')}
                </Text>
                <Form>
                  <Form.Item data-testid='search-placeholder'>
                    <Input
                      prefix={<SearchIcon color={Colors.WHITE_200} />}
                      style={{ borderRadius: 100, paddingRight: 20 }}
                      placeholder={t('search_placeholder')}
                      onChange={handleSearch}
                    />
                  </Form.Item>
                </Form>
                {filteredReport?.length === 0 ? (
                  <Stack direction="column" justifyContent="center">
                    <Image src={BabyImage} preview={false} height={140} />
                    <Title style={{ textAlign: 'center' }} level={3}>
                      {t('empty_here')}
                    </Title>
                    <Text style={{ textAlign: 'center' }} size="small">
                      {t('empty_here_detail')}
                    </Text>
                  </Stack>
                ) : (
                  <Table
                    data-testid='class-results'
                    columns={columns(t)}
                    dataSource={filteredReport}
                    pagination={{ position: ['bottomCenter'], defaultPageSize: 15, hideOnSinglePage: true }}
                  />
                )}
              </Stack>
          )}
        </Stack>
      </Card>
      {showClassResultPdf && (
        <ClassResultPdfModal
          reports={reports}
          details={detail}
          currentClass={currentClass}
          isOpen={showClassResultPdf}
          onClose={() => setShowClassResultPdf(false)}
        />
      )}
    </>
  );
};

export default ClassResults;

ClassResults.propTypes = {
  detail: PropTypes.object.isRequired,
  reports: PropTypes.array.isRequired,
};