import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'antd';
import { Circle as CircleIcon } from 'react-feather';
import { Button, Stack, Title, Text, Colors } from '@design-system';
import GoodTeammateImage from '@assets/images/icon_goodTeammate.png';
import ResearchImage from '@assets/images/icon_researchPaper.png';
import PersonalInfo from '@assets/images/icon_othersPersonalInfo_v2.png';
import PartyImage from '@assets/images/icon_party.png';
import { useTranslation } from 'react-i18next';

const steps = [
  {
    image: GoodTeammateImage,
    title: 'welcome_title',
    description: 'welcome_description'
  },
  {
    image: ResearchImage,
    title: 'add_class_title',
    description: 'add_class_description'
  },
  {
    image: PersonalInfo,
    title: 'add_student_title',
    description: 'add_student_description'
  },
  {
    image: PartyImage,
    title: 'start_teaching_title',
    description: 'start_teaching_description'
  }
];

const OnboardingTour = ({ onClose, loading }) => {
  const { t } = useTranslation('teacher', { keyPrefix: 'onboarding' });
  const [progress, setProgress] = React.useState(0);

  const handleClick = () => {
    if (progress < 3) {
      setProgress(progress + 1);
      return;
    }
    onClose();
  };

  const lastItem = progress === 3;

  return (
    <Stack data-testid='onboarding-tour' direction="column" spacing={50}>
      <Image src={steps[progress].image} preview={false} />
      <Stack direction="column" alignItems="center" spacing={20} style={{ maxWidth: '500px', minHeight: '108px' }}>
        <Title level={3}>{t(steps[progress].title)}</Title>
        <Text className="text-center">{t(steps[progress].description)}</Text>
      </Stack>
      <Stack spacing={16} justifyContent="center">
        {steps.map((_, index) => {
          const color = index === progress ? Colors.LAVENDER : Colors.WHITE_200;
          return <CircleIcon key={index} size={10} color={color} fill={color} />;
        })}
      </Stack>
      <Stack justifyContent={lastItem ? 'center' : 'space-between'}>
        {!lastItem && (
          <Button type="text" onClick={onClose} loading={loading}>
            {t('skip_tour')}
          </Button>
        )}
        <Button onClick={handleClick} loading={loading}>{lastItem ? t('add_first_class') : t('continue')}</Button>
      </Stack>
    </Stack>
  );
};

export default OnboardingTour;

OnboardingTour.propTypes = {
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
