import React, { useEffect } from 'react';
import { Col, Row } from 'antd';
import { Stack } from '@design-system';
import TeachingResources from './TeachingResources';
import Edubytes from './Edubytes';
import Lessons from './Lessons';
import Assignments from './Assignments';
import { useFlag, useFlagsStatus, useUnleashContext } from '@unleash/proxy-client-react';
import { useAppState } from '@context';

const Overview = () => {
  const { flagsReady } = useFlagsStatus();
  const isGovernmentDemo = useFlag('gov-demo');
  const updateContext = useUnleashContext();
  const { state } = useAppState();
  const { currentUser } = state;

  useEffect(async() => {
    if (currentUser?.email) {
      await updateContext({ email: currentUser.email });
    }
  }, [currentUser, updateContext]);

  return (
    <Row style={{ paddingTop: '50px' }} data-testid='overview-page'>
      {!isGovernmentDemo && flagsReady && (
        <Col span={16}>
          <Stack direction="column" spacing={33}>
            <TeachingResources />
            <Edubytes />
          </Stack>
        </Col>
      )}
      <Col span={isGovernmentDemo && flagsReady ? 24 : 8}>
        <Stack direction="column" spacing={33} style={{ paddingLeft: '33px' }}>
          {!isGovernmentDemo && flagsReady && <Lessons />}
          <Assignments />
        </Stack>
      </Col>
    </Row>

  );
};

export default Overview;
