import React from 'react';
import { Image } from 'antd';
import { Button, Stack, Title, Text } from '@design-system';
import GoodTeammateImage from '@assets/images/icon_goodTeammate.png';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const AddClassPrompt = ({ onClose }) => {
  const { t } = useTranslation('teacher', { keyPrefix: 'onboarding' });
  return (
    <Stack data-testid='add-class-prompt' direction="column" spacing={50}>
      <Image src={GoodTeammateImage} preview={false} />
      <Stack direction="column" alignItems="center" spacing={20} style={{ maxWidth: '500px' }}>
        <Title level={3}>{t('welcome_back_title')}</Title>
        <Text className="text-center">{t('welcome_back_description')}</Text>
      </Stack>
      <Button onClick={onClose}>{t('add_class')}</Button>
    </Stack>
  );
};
AddClassPrompt.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default AddClassPrompt;
