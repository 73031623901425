import React from 'react';
import { Collapse } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';
import { Plus, Minus } from 'react-feather';
import { Colors, Card, Stack, Text, Link, Title } from '@design-system';
import { useNavigate } from 'react-router-dom';

const StyledCollapse = styled(Collapse)`
    border: none;
    background: none;
    .ant-collapse-item {
        border-bottom: 1.46px solid #C2CEED
    }
    .ant-collapse-header {
        padding: 20px 0 !important;
        font-family: Lexend, sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
    }
    .ant-collapse-header-text {
        padding-right: 40px;
    }
    .ant-collapse-content {
        border: none;
    }
    .ant-collapse-content-box {
        padding: 20px 0 !important;
        font-family: Lexend, sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
    }
`;

const getFaqData = (t, navigate) => [
  {
    question: t('reset_password_question'),
    answer: () => (
        <Trans i18nKey="reset_password_answer" t={t}>
          Reset your password by going
          <Link hideIcon style={{ display: 'inline-block' }} onClick={() => navigate('/settings')}>
            here
          </Link>
          and following the instructions.
        </Trans>
    )
  },
  {
    question: t('create_multiple_classrooms_question'),
    answer: () => (
        <Trans i18nKey="create_multiple_classrooms_answer" t={t}>
          Your account allows a maximum of 2 classrooms. However, if you require more classrooms, please contact us at
          <Link hideIcon style={{ display: 'inline-block' }} href="mailto:support@cyberlegends.com" target="_blank">
            support@cyberlegends.com
          </Link>
        </Trans>
    )
  },
  {
    question: t('remove_child_classroom_question'),
    answer: () => (
        <ul style={{ listStyleType: 'disc'}}>
          <li>{t('remove_child_classroom_answer.0')}</li>
          <li>{t('remove_child_classroom_answer.1')}</li>
          <li>{t('remove_child_classroom_answer.2')}</li>
        </ul>
    )
  },
  {
    question: t('edit_child_name_question'),
    answer: () => (
        <ul style={{ listStyleType: 'disc'}}>
          <li>{t('edit_child_name_answer.0')}</li>
          <li>{t('edit_child_name_answer.1')}</li>
          <li>{t('edit_child_name_answer.2')}</li>
        </ul>
    )
  },
  {
    question: t('access_resources_multiple_grades_question'),
    answer: () => (
        <ul style={{ listStyleType: 'disc'}}>
          <li>{t('access_resources_multiple_grades_answer.0')}</li>
          <li>{t('access_resources_multiple_grades_answer.1')}</li>
          <li>{t('access_resources_multiple_grades_answer.2')}</li>
        </ul>
    )
  },
  {
    question: t('add_students_to_dashboard_question'),
    answer: () => (
        <ul style={{ listStyleType: 'disc'}}>
          <li>{t('add_students_to_dashboard_answer.0')}</li>
          <li>{t('add_students_to_dashboard_answer.1')}</li>
        </ul>
    )
  },
  {
    question: t('add_students_to_dashboard_how_question'),
    answer: () => (
        <ul style={{ listStyleType: 'disc'}}>
          <li>
            <Trans i18nKey="add_students_to_dashboard_how_answer.0" t={t}>
              <Link hideIcon style={{ whiteSpace: 'break-spaces' }} href="https://youtu.be/LFsKK3jLZP8" target="_blank" >
                Watch this a video showing how to add students to the teacher dashboard
              </Link>
            </Trans>
          </li>
          <li>
            {t('add_students_to_dashboard_how_answer.1')}
            <ul style={{ listStyleType: 'circle'}}>
              <li>{t('add_students_to_dashboard_how_answer.2')}</li>
              <li>{t('add_students_to_dashboard_how_answer.3')}</li>
            </ul>
          </li>
        </ul>
    )
  },
  {
    question: t('link_caregiver_parent_question'),
    answer: () => (
        <ul style={{ listStyleType: 'disc'}}>
          <li>{t('link_caregiver_parent_answer.0')}</li>
          <li>{t('link_caregiver_parent_answer.1')}</li>
        </ul>
    )
  },
  {
    question: t('link_caregiver_parent_how_question'),
    answer: () => (
        <ul style={{ listStyleType: 'disc'}}>
          <li>{t('link_caregiver_parent_how_answer.0')}</li>
          <li>{t('link_caregiver_parent_how_answer.1')}</li>
          <li>{t('link_caregiver_parent_how_answer.2')}</li>
          <li>{t('link_caregiver_parent_how_answer.3')}</li>
          <li>{t('link_caregiver_parent_how_answer.4')}</li>
          <li>{t('link_caregiver_parent_how_answer.5')}</li>
        </ul>
    )
  },
  {
    question: t('classroom_next_year_question'),
    answer: () => (
        <ul style={{ listStyleType: 'disc'}}>
          <li>
            {t('classroom_next_year_answer.0')}
            <ul style={{ listStyleType: 'circle'}}>
              <li>
                <Trans i18nKey="classroom_next_year_answer.1" t={t}>
                  Every year, you can choose to either <b>create a new classroom</b> (for a maximum total of 2
                  classrooms) OR
                </Trans>
              </li>
              <li>
                {t('classroom_next_year_answer.2')}
                <ul>
                  <li>{t('classroom_next_year_answer.3')}</li>
                  <li>{t('classroom_next_year_answer.4')}</li>
                  <li>{t('classroom_next_year_answer.5')}</li>
                </ul>
              </li>
              <li>
                <Trans i18nKey="classroom_next_year_answer.6" t={t}>
                  <b>Importantly:</b> Before adding in new students, ask your students who already has a (pre-existing)
                  Cyber Legends account. Those students will be able to{' '}
                  <b>link their account to their new classroom using the classroom code</b>. If you accidentally create
                  a new account for a student who has a pre-existing account, you can go back and easily delete that
                  extra account.
                </Trans>
              </li>
            </ul>
          </li>
        </ul>
    )
  },
  {
    question: t('student_progress_info_question'),
    answer: () => (
        <ul style={{ listStyleType: 'disc'}}>
          <li>{t('student_progress_info_answer.0')}</li>
          <li>{t('student_progress_info_answer.1')}</li>
          <li>{t('student_progress_info_answer.2')}</li>
        </ul>
    )
  },
  {
    question: t('classroom_code_question'),
    answer: () => (
          <>{t('classroom_code_answer.0')}</>
    )
  },
  {
    question: t('more_than_22_kids_question'),
    answer: () => (
            <Trans i18nKey="more_than_22_kids_answer.0" t={t}>
              If you require more than 22 students in your classroom, please contact us at
              <Link hideIcon style={{ display: 'inline-block' }} href="mailto:support@cyberlegends.com" target="_blank">
                support@cyberlegends.com
              </Link>
            </Trans>
    )
  },
  {
    question: t('cyber_legends_assignments_question'),
    answer: () => (
          <>{t('cyber_legends_assignments_answer.0')}</>
    )
  },
  {
    question: t('create_assignment_question'),
    answer: () => (
        <ul style={{ listStyleType: 'disc'}}>
          <li>
            <Trans i18nKey="create_assignment_answer.0" t={t}>
              <Link hideIcon style={{ whiteSpace: 'break-spaces' }} href="https://youtu.be/QvRhg41-w39nc" target="_blank" >
                Watch this video showing how to create an assignment
              </Link>
            </Trans>
          </li>
          <li>{t('create_assignment_answer.1')}</li>
          <li>{t('create_assignment_answer.2')}</li>
          <li>{t('create_assignment_answer.3')}</li>
          <li>{t('create_assignment_answer.4')}</li>
          <li>{t('create_assignment_answer.5')}</li>
        </ul>
    )
  },
  {
    question: t('same_number_questions_assigned_quests_question'),
    answer: () => (
          <>{t('same_number_questions_assigned_quests_answer.0')}</>
    )
  }
];

const FAQs = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'support' });
  const { Panel } = Collapse;
  const navigate = useNavigate();

  return (
    <Card data-testid="teacher-faqs">
      <Stack direction='column' alignItems='flex-start' spacing={30}>
      <Title level={3}>{t('frequently_asked_questions')}</Title>
      <StyledCollapse
        expandIcon={({ isActive }) =>
          isActive ? <Minus size={16} style={{ color: Colors.VIOLET }} /> : <Plus size={16} style={{ color: Colors.VIOLET }} />
        }
        expandIconPosition={'end'}>
        {getFaqData(t, navigate).map((item, index) => (
          <Panel header={item.question} key={index}>
            <Text size='small'>{item.answer()}</Text>
          </Panel>
        ))}
      </StyledCollapse>
      </Stack>
    </Card>
  );
};

export default FAQs;
