import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { msalInstance, SINGLE_SIGN_ON_PROVIDER } from './authConfig';
import microsoftImage from '@assets/images/microsoftImage.png';
import { clearCognitoStorage } from '@utils';
import { Button } from '@design-system';
import { message } from 'antd';

function MicrosoftAuth() {
  const [loginLoading, setLoginLoading] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    // Initialize msalInstance
    const initializeMsal = async () => {
      try {
        await msalInstance.initialize();
        setIsInitialized(true);
        // Process redirect response after initialization
        const response = await msalInstance.handleRedirectPromise();
        if (response) {
          // If successful, use the token to sign in or sign up in Cognito
          setLoginLoading(true);
          signUpOrSignInWithMicrosoftToken(response.idToken);
        }
      } catch (error) {
        message.error('MicrosoftAuth failed !');
      }
    };

    initializeMsal();
  }, []);

  const onSignInWithProvider = provider => {
    if (!isInitialized) return; // Ensure MSAL is initialized

    if (provider === SINGLE_SIGN_ON_PROVIDER.MICROSOFT) {
      msalInstance.loginRedirect({
        scopes: ['profile', 'openid', 'email'],
        prompt: 'select_account'
      });
    }
  };

  // Sign up or sign in with Microsoft token
  const signUpOrSignInWithMicrosoftToken = async microsoftIdToken => {
    if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }
    authenticateWithMicrosoftToken(microsoftIdToken);
  };

  // Authenticate with Microsoft token
  const authenticateWithMicrosoftToken = async microsoftIdToken => {
    const userName = msalInstance.getActiveAccount().username.toLowerCase();
    try {
      const cognitoUser = await Auth.signIn(userName);
      await Auth.sendCustomChallengeAnswer(cognitoUser, microsoftIdToken);
      setLoginLoading(false);
    } catch (e) {
      if (e.message.includes('Username Found')) {
        const newUserName = e.message.split('Username Found')[1].trim().replace(/\./g, '');
        try {
          const cognitoUser = await Auth.signIn(newUserName);
          await Auth.sendCustomChallengeAnswer(cognitoUser, microsoftIdToken);
          setLoginLoading(false);
        } catch (e) {
          message.error(`Authentication failed: ${e.message}`);
        }
      } else {
        message.error(`Authentication failed: ${e.message}`);
      }
    }
    setLoginLoading(false);
  };

  const microsoftSignIn = () => {
    setLoginLoading(true);
    clearCognitoStorage();
    sessionStorage.clear();
    onSignInWithProvider(SINGLE_SIGN_ON_PROVIDER.MICROSOFT);
  };

  return (
    <Button
      onClick={microsoftSignIn}
      type="button"
      loading={loginLoading}
      dataTestId="microsoftSignInButton"
      icon={<img src={microsoftImage} alt="googleLogo" width={31} height={31} />}>
      Sign In with Microsoft
    </Button>
  );
}

export default MicrosoftAuth;
