/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ConfigProvider, message } from 'antd';
import { PrivateRoute, PublicRoute } from './routes';
import {
  AuthGoogle,
  CreateSchoolSetup,
  CurriculumSetup,
  EmailConformation,
  ForgetPassword,
  Payment,
  ResetPassword,
  SchoolSetup,
  SignedUp,
  SignIn,
  SignUp
} from './components/authComponents';

import {
  MakePayment,
  ManageSubscriptions,
  Referrals,
  Subscriptions,
  UpdatePayment
} from './components/mainComponents';

import { Dashboard, TeacherDashboard } from '@pages/Dashboard';
import { NewPassword } from '@pages/TeacherSignUp/NewPassword';
import { Settings } from '@pages/Settings';
import { MembershipAndBilling } from '@pages/MembershipAndBilling';
import { NotFound } from '@pages/NotFound';
import { EdubytesDetail } from '@pages/Edubytes';
import { Home } from '@pages/Home';

// New Teacher routes
import { NewPlanner, Planner } from '@pages/Planner';
import Edubytes from '@pages/Resources/Edubytes';
import Support from '@pages/Support';
import SupportGlossary from '@pages/SupportGlossary';
import InClassResources from '@pages/Resources/InClassResources';
import NewAssignment from '@pages/Assignment/NewAssignment';
import AssignmentReport from '@pages/AssignmentReport/AssignmentReport';
import Students from '@pages/Students/Students';
import Assignments from '@pages/Assignment/Assignments';

import { SignUpHome } from '@pages/SignUpHome';
import * as SchoolAdminSignup from '@pages/Signup/SchoolAdmin';
import { TeachingResourcesDetail } from '@pages/TeachingResources';
import { Logout } from '@pages/Logout';
import { Referrals as TeacherReferrals } from '@pages/Referrals';
import { SelectLicence } from '@pages/Signup/Teacher';

import { awsConfig } from '@utils';
import { useDetectRouteChange, useDocumentTitle, usePageTracking } from '@hooks';
import './App.scss';
import { useModal } from '@context/modalContext';
import i18n from '@services/i18n';
import frLocale from 'antd/es/locale/fr_CA';
import enLocale from 'antd/es/locale/en_US';
import 'moment/locale/fr-ca';
import AuthCallback from '@pages/AuthCallback';

Amplify.configure(awsConfig());
message.config({
  duration: 10,
  maxCount: 1
});

const App = () => {
  usePageTracking();
  useDetectRouteChange();
  const { title } = useDocumentTitle();
  const { modalContent } = useModal();

  return (
    <ConfigProvider locale={i18n.language === 'fr-CA' ? frLocale : enLocale}>
      <HelmetProvider>
        <Helmet>
          <title>{title ? `${title} - ` : ''}Cyber Legends</title>
        </Helmet>
        <Routes>
          <Route
            path="/"
            element={
              <PublicRoute restricted>
                <Home />
              </PublicRoute>
            }
          />
          <Route
            path="/teacher-signin"
            element={
              <PublicRoute restricted>
                <SignIn />
              </PublicRoute>
            }
          />

          <Route
            path="/microsoft-authcallback"
            element={
              <PublicRoute restricted>
                <AuthCallback />
              </PublicRoute>
            }
          />
          <Route
            path="/auth"
            element={
              <PublicRoute restricted>
                <AuthGoogle />
              </PublicRoute>
            }
          />
          <Route path="/teacher-create-account" element={<PublicRoute restricted />}>
            <Route index element={<SignUp />} />
            <Route path="select-licence" element={<SelectLicence />} />
          </Route>
          <Route
            path="/create-account-landing"
            element={
              <PublicRoute restricted>
                <SignUpHome />
              </PublicRoute>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <PublicRoute restricted>
                <ForgetPassword />
              </PublicRoute>
            }
          />
          <Route
            path="/reset-password"
            element={
              <PublicRoute restricted>
                <ResetPassword />
              </PublicRoute>
            }
          />
          <Route
            path="/teacher-create-account/email-confirmation"
            element={
              <PublicRoute restricted>
                <EmailConformation />
              </PublicRoute>
            }
          />
          <Route
            path="/teacher-create-account/curriculum-setup"
            element={
              <PublicRoute restricted={false}>
                <CurriculumSetup />
              </PublicRoute>
            }
          />
          <Route
            path="/teacher-create-account/school-setup"
            element={
              <PublicRoute restricted={false}>
                <SchoolSetup />
              </PublicRoute>
            }
          />
          <Route
            path="/teacher-create-account/create-school"
            element={
              <PublicRoute restricted={false}>
                <CreateSchoolSetup />
              </PublicRoute>
            }
          />
          <Route
            path="/teacher-create-account/payment-setup"
            element={
              <PublicRoute restricted>
                <Payment />
              </PublicRoute>
            }
          />
          <Route path="/teacher-create-account/signed-up" element={<SignedUp />} />
          <Route path="/teacher-signup" element={<PublicRoute restricted />}>
            <Route path="new-password" element={<NewPassword />} />
          </Route>
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/teacher-dashboard"
            element={<PrivateRoute>{value => <TeacherDashboard {...value} />}</PrivateRoute>}
          />
          <Route
            path="/students"
            element={
              <PrivateRoute>
                <Students />
              </PrivateRoute>
            }
          />
          <Route path="/resources" element={<PrivateRoute />}>
            <Route index element={<InClassResources />} />
            <Route path=":id" element={<TeachingResourcesDetail />} />
          </Route>
          <Route path="/edubytes" element={<PrivateRoute />}>
            <Route index element={<Edubytes />} />
            <Route path=":id" element={<EdubytesDetail />} />
          </Route>
          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            }
          />
          <Route path="/assignments" element={<PrivateRoute />}>
            <Route index element={<Assignments />} />
            <Route path=":id" element={<NewAssignment />} />
            <Route path="report/:id" element={<AssignmentReport />} />
          </Route>
          <Route path="/planner" element={<PrivateRoute />}>
            <Route index element={<Planner />} />
            <Route path="new" element={<NewPlanner />} />
          </Route>
          <Route path="/support" element={<PrivateRoute />}>
            <Route index element={<Support />} />
            <Route path="glossary-terms" element={<SupportGlossary />} />
          </Route>
          <Route path="/referrals-credits" element={<PrivateRoute />}>
            <Route index element={<TeacherReferrals />} />
          </Route>
          <Route
            path="/referrals"
            element={
              <PrivateRoute>
                <Referrals />
              </PrivateRoute>
            }
          />
          <Route
            path="/memberships-and-billing"
            element={
              <PrivateRoute>
                <MembershipAndBilling />
              </PrivateRoute>
            }
          />
          <Route
            path="/subscriptions"
            element={
              <PrivateRoute>
                <Subscriptions />
              </PrivateRoute>
            }
          />
          <Route
            path="/manage-subscription"
            element={
              <PrivateRoute>
                <ManageSubscriptions />
              </PrivateRoute>
            }
          />
          <Route
            path="/make-payment"
            element={
              <PrivateRoute>
                <MakePayment />
              </PrivateRoute>
            }
          />
          <Route
            path="/update-card"
            element={
              <PrivateRoute>
                <UpdatePayment />
              </PrivateRoute>
            }
          />
          {/* School admin Signup flow */}
          <Route path="/school-admin/*" element={<PublicRoute restricted />}>
            <Route path="create-account" element={<SchoolAdminSignup.CreateAccount />} />
            <Route path="email-confirmation" element={<SchoolAdminSignup.EmailConfirmation />} />
            <Route path="school-setup" element={<SchoolAdminSignup.SchoolSetup />} />
            <Route path="create-school" element={<SchoolAdminSignup.CreateSchoolSetup />} />
          </Route>

          <Route path="/logout" element={<Logout />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
        {modalContent}
      </HelmetProvider>
    </ConfigProvider>
  );
};

export default App;
