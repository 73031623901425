import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, message, Skeleton } from 'antd';
import { Copy, Edit2, X } from 'react-feather';
import { Button, Colors, Form, Modal, Stack, Text, Title } from '@design-system';
import { useTranslation } from 'react-i18next';
import { LoginInstructionsPdf } from '@components/pdf';
import { API_URL, getApiWithAuth, patchApiWithAuth, REACT_APP_CYBER_ACADEMY_URL } from '@utils';
import { useAppState } from '@context';

const StudentLoginDetailsModal = ({ isOpen, onClose, selectedLoginDetails }) => {
  const [loading, setLoading] = useState(true);
  const [loginDetail, setLoginDetail] = useState(null);

  const [isEditPasswordDisabled, setIsEditPasswordDisabled] = useState(true);

  const { state } = useAppState();
  const currentClass = state.classes.find(item => item.id === state.classId);

  const { t } = useTranslation('teacher', { keyPrefix: 'students' });
  const [form] = Form.useForm();

  useEffect(async () => {
    if (!selectedLoginDetails) return;
    setLoading(true);
    const { success, data } = await getApiWithAuth(
      `${API_URL.EDIT_CLASS_BY_ID}${currentClass.id}/students/login-details/${selectedLoginDetails.id}`
    );

    if (success) {
      setLoading(false);
      setLoginDetail(data);
    } else {
      message.error(data.message);
      setLoading(false);
    }
  }, [selectedLoginDetails]);

  useEffect(() => {
    if (loginDetail && selectedLoginDetails?.id) {
      form.setFieldsValue({
        username: loginDetail?.username,
        password: loginDetail?.password
      });
    }
  }, [selectedLoginDetails?.id, loginDetail]);

  const copyToClipboard = async (classCode, key) => {
    try {
      await navigator.clipboard.writeText(classCode);
      message.success(t(`${key}_copied`));
    } catch (error) {
      message.error(t(`${key}_copy_failed`));
    }
  };

  const cancelEditing = () => {
    setIsEditPasswordDisabled(true);
    form.setFieldsValue({
      password: loginDetail?.password
    });
  };

  const startEditing = () => {
    setIsEditPasswordDisabled(false);
  };

  const updatePassword = async () => {
    const newPassword = form.getFieldValue('password');
    if (newPassword.length < 6 || newPassword.length > 16) {
      message.error('Password must be between 6 and 16 characters.');
    }

    setLoading(true);
    const { success, data } = await patchApiWithAuth(
      `${API_URL.EDIT_CLASS_BY_ID}${currentClass.id}/students/login-details/${selectedLoginDetails.id}`,
      {
        new_password: newPassword,
        current_password: loginDetail?.password
      }
    );

    if (success) {
      setLoading(false);
      setLoginDetail(data);

      setIsEditPasswordDisabled(true);
    } else {
      message.error(data.message);
      setLoading(false);
    }
  };

  return (
    <Modal data-testid="student-login-details-modal" width={585} centered={false} onCancel={onClose} open={isOpen}>
      <Form form={form} initialValues={{ username: loginDetail?.username, password: loginDetail?.password }}>
        {loading ? (
          <Skeleton active />
        ) : (
          <Stack direction="column" spacing={22}>
            <Stack direction="column" alignItems="flex-start" spacing={12}>
              <Title level={3}>{t('student_login_details')}</Title>
              <Text size="small">{t('student_Login_details_subtext', { url: REACT_APP_CYBER_ACADEMY_URL })}</Text>
              <Form.Item data-testid="student-login-username" label={`${t('username')}:`} name="username">
                <Input
                  type="text"
                  disabled
                  name="username"
                  suffix={
                    <Copy
                      data-testid="student-username-copy"
                      style={{ cursor: 'pointer', color: Colors.VIOLET }}
                      onClick={() => copyToClipboard(loginDetail?.username, 'username')}
                    />
                  }
                />
              </Form.Item>
              <Form.Item data-testid="student-login-password" label={`${t('password')}:`} name="password">
                <Input
                  type="text"
                  disabled={isEditPasswordDisabled}
                  name="password"
                  suffix={
                    isEditPasswordDisabled ? (
                      <>
                        <Edit2 style={{ cursor: 'pointer', color: 'blue' }} onClick={startEditing} />
                        <Copy
                          data-testid="student-password-copy"
                          style={{ cursor: 'pointer', color: Colors.VIOLET }}
                          onClick={() => copyToClipboard(loginDetail?.password, 'password')}
                        />
                      </>
                    ) : (
                      <X style={{ cursor: 'pointer', color: 'red' }} onClick={cancelEditing} />
                    )
                  }
                />
              </Form.Item>
            </Stack>
            {isEditPasswordDisabled ? (
              <Button data-testid="loginDetail-pdf-download-button" block>
                {loginDetail && <LoginInstructionsPdf loginDetail={loginDetail} />}
              </Button>
            ) : (
              <Button data-testid="loginDetail-update-password-button" block onClick={updatePassword}>
                {t('update_password')}
              </Button>
            )}
          </Stack>
        )}
      </Form>
    </Modal>
  );
};

export default StudentLoginDetailsModal;

StudentLoginDetailsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedLoginDetails: PropTypes.object.isRequired
};
